import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';
// helpers
import { _isUndefined } from '@/utils/jsHelpers/obj';
// components
import Loader from '@/components/ui/Loader';
// API helper
import api from '@/api';
// styles
import styles from './styles.module.scss';
import { SETUP_BASE_URL } from '@/config';

/** NOTE - this page is a callback page for WorkOS SSO-
 *  It receives two GET parameters 'code' and 'state'; code is sent to the
 *  backend to validate it with WorkOS and get the SSO user profile. */
function WorkOSAuthPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  // Parse the GET parameters- 'code' and 'state'
  // NOTE - state is JSON and could have a 'ref' or 'eventId' property
  const parsed = queryString.parse(props.location.search);
  const { code } = parsed;
  const { state } = parsed;
  let ref;
  let eventId;
  // Get the ref (redirect) from the state JSON
  if (state !== 'undefined' && !_isUndefined(state)) {
    const stateString = String(state);
    const stateJson = JSON.parse(stateString);
    // Get the 'ref' from the state JSON
    if (stateJson && stateJson.ref) {
      ref = stateJson.ref;
    }
    // Get the 'eventId' from the state JSON
    if (stateJson && stateJson.eventId) {
      eventId = stateJson.eventId;
    }
  }

  useEffect(() => {
    let isAttendeeRegistration = false;
    (async () => {
      if(eventId) {
        await api.event.getLandingPageByEventById(eventId).then(({ data }) => {
          let eventEntryType = data?.eventEntryType;
          if(eventEntryType && eventEntryType !== 'SSO_ONLY' && ref && ref.includes('/p/a')) {
            isAttendeeRegistration = true;
          }
        });
      }

      // Verify this code with the WorkOS API
      await api.account
        .verifyWorkOSCode_landingPage(code, state, isAttendeeRegistration)
        .then(() => {
          // If eventId is null/undefined, redirect back to the event list, else
          //  redirect to the event landing page.
          if (eventId == null) {
            window.location.href = SETUP_BASE_URL;
          } else if (ref == null) {
            window.location.href = `/p/a/event/${eventId}`;
          } else {
            window.location.href = ref;
          }
        })
        .catch(e => {
          console.error(e);
          // 406 "Not Acceptable" error means they are using the wrong auth mode/type
          if (e.response && e.response.status === 406) {
            // Redirect to the Attendee page to complete the event registration flow
            if (eventId != null) {
              history.push(`/p/a/event/${eventId}`);
            } else {
              history.push('/a/signin');
            }
            if(e.response.data.message === 'NOT_ACCEPTABLE') {
              dispatch({
                type: 'global/addDangerToast',
                payload: {
                  description: e.response.data.description,
                },
              });
            } else {
              // TODO- set the state to show the appropriate social login button
              //  e.g. if facebook user, then show "Click here to sign into facebook."
              dispatch({
                type: 'global/addDangerToast',
                // Toast will say "You need to use LinkedIn to login." or whatever auth method they used last
                payload: {
                  description: `You need to use ${e.response.data} to login.`,
                },
              });
            }
          } else {
            console.log('debugSSO > error > ', e);
            // Redirect the user back to the signin with the same 'ref' redirect GET parameter
            let url = '/a/signin';
            if (!_isUndefined(ref)) {
              url += `?ref=${ref}`;
            }
            history.push(url);
            // Show a warning at the login page that SSO Login failed
            dispatch({
              type: 'global/addDangerToast',
              payload: { description: 'Unable to sign in with SSO' },
            });
          }
        });
    })();
  }, []);
  return (
    <div>
      <div className={styles.loadingPageLoader}>
        <h3>Loading, please wait...</h3>
        <Loader />
      </div>
    </div>
  );
}

export default WorkOSAuthPage;
