import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectUserAccountId } from '@/models/account';
import api from '@/api';
import { makeSelectCurrentPluginForChannel } from '@/models/event';

import {
  TCurrentPresentationUI,
  IUseChannelPresentationResponse,
} from './useChannelPresentation.types';

const useChannelPlugin = ({ channelId, attendeeChannel }) => {
  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
  const dispatch = useDispatch();

  const currentPlugin: TCurrentPresentationUI = useMemoizedSelector(
    makeSelectCurrentPluginForChannel,
    channelId,
  );
  const setCurrentPlugin = plugin => {
    dispatch({
      type: 'event/setCurrentPlugin',
      payload: {
        channelId,
        plugin,
      },
    });
  };

  const setCurrentPluginWithApiResponse = apiResponse => {
    if (!apiResponse) {
      setCurrentPlugin(null);
      return;
    }
    const {
      channelPluginId: pluginId,
      pluginName,
      pluginUrl,
      pluginPublishedBy,
      isActive,
      pluginType,
    } = apiResponse;
    if (!isActive) {
      setCurrentPlugin(null);
    }

    const currentPluginObj = {
      pluginId,
      pluginName,
      pluginUrl,
      pluginPublishedBy,
      pluginType,
    };
    setCurrentPlugin(currentPluginObj);
  };

  const updateCurrentPluginState = () => {
    api.channel.getCurrentPlugin(channelId).then(({ data }) => {
      setCurrentPluginWithApiResponse(data);
    });
  };

  const add = (name: string, url: string, pluginType) =>
    api.channel
      .addPlugin({ channelId, name, url, pluginType, isActive: true })
      .catch(() => {
        refreshCurrentPluginState();
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: `There was a problem adding the ${pluginType} presentation, please try again or check anyone else have presented ${pluginType} screen`,
          },
        });
      });

  const stop = () => {
    api.channel.stopPlugin(channelId).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description:
            'There was a problem stopping the plugin, please try again',
        },
      });
    });
  };

  const refreshCurrentPluginState = () => {
    updateCurrentPluginState();
  };

  const setLoadingPlugin = () => {
    setCurrentPlugin({
      pluginId: 'loading-plugin',
      pluginName: 'Loading Plugin',
      isLoading: true,
      pluginPublishedBy: currentUserAccountId,
      pluginType: 'Loading Plugin',
    });
  };

  useEffect(() => {
    if (!channelId) {
      return;
    }
    updateCurrentPluginState();
  }, [channelId]);

  useEffect(() => {
    if (!attendeeChannel || !currentUserAccountId) {
      return;
    }

    const setPluginHandler = data => {
      const pluginData = JSON.parse(data);

      if (!pluginData) {
        setCurrentPluginWithApiResponse(null);
        return;
      }
      setCurrentPluginWithApiResponse(pluginData);
    };

    attendeeChannel.bind('SET_CHANNEL_PLUGIN', setPluginHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      attendeeChannel.unbind('SET_CHANNEL_PLUGIN', setPluginHandler);
    };
  }, [attendeeChannel, currentUserAccountId, currentPlugin]);

  return {
    currentPlugin,
    addPlugin: add,
    stopPlugin: stop,
    refreshCurrentPluginState: refreshCurrentPluginState,
    setLoadingPlugin,
  };
};

export { IUseChannelPresentationResponse };

export default useChannelPlugin;
