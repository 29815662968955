const defaultState = {
  pusher: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'initPusher': {
      const { pusher } = action.payload;
      return {
        ...state,
        pusher
      };
    }
    case 'destroyPusher': {
      return {
        pusher: null
      };
    }
    default:
      throw new Error('pusher state > mutation type not defined');
  }
};

export { defaultState, reducer };
