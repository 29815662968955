import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import api from '@/api';
import { ChannlRefType as ChannelRefType } from '@/types/channel';
import { RedirectTypes } from '@/components/PublishedStreamDisplay/RedirectOverlay/config';
import { makeSelectIsOrganizer } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { IStageParent } from '@/components/Stage/types';
import { AttendeeChannelEvent } from '@/types/messaging';
import queryString from 'query-string';
import { STUDIO_URL } from '@/config';

const useBreakoutAttendeeChannel = ({ attendeeChannel, parent }) => {
  const {
    eventId = '',
    stageId = '',
    discussionTableId: currentDiscussionTableId = '',
  } = useParams<{
    eventId: string;
    stageId: string;
    discussionTableId: string;
  }>();
  const dispatch = useDispatch();
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const [redirectType, setRedirectType] = useState<null | RedirectTypes>(null);
  const [redirectTo, setRedirectTo] = useState('');
  const [breakoutConfig, setBreakoutConfig] = useState('') as any;
  const { search } = useLocation();
  const searchParams = queryString.parse(search);

  useEffect(() => {
    // below condition calls if user in room
    if (currentDiscussionTableId && eventId) {
      api.breakout
        .getStageBreakoutConfig(
          eventId,
          currentDiscussionTableId,
          ChannelRefType.SESSION,
        )
        .then(({ data: breakoutData }) => {
          setBreakoutConfig(breakoutData);
        });
      return;
    }
    // below condition calls if user in stage or backstage
    if (eventId && stageId) {
      api.breakout
        .getBreakoutConfigByStageId(eventId, stageId)
        .then(({ data: breakoutData }) => {
          setBreakoutConfig(breakoutData);
        });
    }
  }, [eventId, stageId, currentDiscussionTableId]);

  const getUserBreakoutInfo = (breakoutId, finalFetch = false) => {
    api.breakout
      .getUserBreakoutRoom(eventId, breakoutId)
      .then(({ data: breakoutData }) => {
        const { discussionTableId } = breakoutData;
        if (!discussionTableId || discussionTableId.length === 0) {
          // Do nothing if the user is not assigned a breakout room. Just stay there.
          if (!finalFetch) getUserBreakoutInfo(breakoutId, true);
        } else {
          const breakoutRoomUrl = `/l/event/${eventId}/discussions/${discussionTableId}?parentRef=${`stages/${stageId}`}`;
          setRedirectType(RedirectTypes.Breakout);
          setRedirectTo(breakoutRoomUrl);
        }
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Failed to fetch breakout info.' },
        });
      });
  };

  const [breakoutInterval, setBreakoutInterval] = useState(null) as any;
  const onBreakout = data => {
    try {
      const notification = JSON.parse(data);
      const delayMs = 2000 + Math.random() * 1000;
      // for redirect rooms page after 5sec timer.
      const breakoutTimer = setTimeout(() => {
        getUserBreakoutInfo(notification.breakoutId);
      }, delayMs);
      setBreakoutInterval(breakoutTimer);
    } catch (e) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Failed to parse breakout notification.' },
      });
    }
  };

  useEffect(
    () => () => {
      if (breakoutInterval) {
        clearTimeout(breakoutInterval);
      }
    },
    [],
  );

  const onBringBack = data => {
    try {
      const notification = JSON.parse(data);
      setRedirectType(RedirectTypes.BringBack);
      const { parentRef = 'discussions' } = searchParams;
      if (parentRef?.includes('studio/')) {
        setRedirectTo(`${STUDIO_URL}/${parentRef}`);
      } else {
        const stageUrl = `/l/event/${eventId}/stages/${notification.stageId}`;
        setRedirectTo(stageUrl);
      }
      if (isOrganizer && parent === IStageParent.LIVE_DISCUSSION) {
        dispatch({
          type: 'event/updateBreakoutRoomsConfigStatus',
          payload: {
            stageId: notification.stageId,
            status: false,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Failed to fetch breakout Info.' },
      });
    }
  };

  const getUserInBreakoutRoom = (intervalObject: any) => {
    api.breakout
      .isStageInBreakout(eventId, stageId)
      .then(({ data: breakoutData }) => {
        if (!breakoutData) {
          return;
        }
        const { discussionTableId, inBreakout } = breakoutData;
        if (discussionTableId && inBreakout) {
          console.error('RAGHU useBrekaoutAttendeeChannel > getUserInBreakoutRoom');
          const breakoutRoomUrl = `/l/event/${eventId}/discussions/${discussionTableId}?parentRef=${`stages/${stageId}`}`;
          setRedirectType(RedirectTypes.Breakout);
          setRedirectTo(breakoutRoomUrl);
          if (intervalObject) {
            clearInterval(intervalObject);
          }
        }
      });
  };

  // call every 10 sec for fetching user breakout room info if pusher not works
  // only for speakers and attendees. bcz organizers can stay in stage.
  // this is for sending user from stage to rooms
  useEffect(() => {
    if (
      !breakoutConfig ||
      !breakoutConfig.breakoutId ||
      (parent !== IStageParent.LIVE_STAGE &&
        parent !== IStageParent.LIVE_BACKSTAGE) ||
      isOrganizer
    ) {
      return;
    }
    // calling on stage page load
    getUserInBreakoutRoom(null);

    const interval = setInterval(() => {
      getUserInBreakoutRoom(interval);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [breakoutConfig, parent, isOrganizer]);

  const checkStageInBreakout = (breakoutStageId: string, intervalObject) => {
    api.breakout
      .getBreakoutConfigByStageId(eventId, breakoutStageId)
      .then(({ data: breakoutData }) => {
        if (breakoutData && !breakoutData.inBreakout) {
          onBringBack(JSON.stringify(breakoutData));
          if (intervalObject) {
            clearInterval(intervalObject);
          }
        }
      });
  };

  // call every 10 sec for fetching stage is in breakout or not
  // this is for sending user from room to stage back
  useEffect(() => {
    if (
      !breakoutConfig ||
      !breakoutConfig.breakoutId ||
      parent !== IStageParent.LIVE_DISCUSSION
    ) {
      return;
    }
    // checking breakout room stopped or not, if stopped move back to stage
    checkStageInBreakout(breakoutConfig.stageId, null);

    const interval = setInterval(() => {
      checkStageInBreakout(breakoutConfig.stageId, interval);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [parent, breakoutConfig]);

  useEffect(() => {
    if (!attendeeChannel) return;

    attendeeChannel.bind(AttendeeChannelEvent.STAGE_BREAKOUT, onBreakout);
    attendeeChannel.bind(AttendeeChannelEvent.STAGE_BRINGBACK, onBringBack);

    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.STAGE_BREAKOUT, onBreakout);
      attendeeChannel.unbind(AttendeeChannelEvent.STAGE_BRINGBACK, onBringBack);
    };
  }, [attendeeChannel]);

  return {
    redirectType,
    redirectTo,
    breakoutConfig,
    setRedirectType,
    getUserBreakoutInfo,
  };
};

export default useBreakoutAttendeeChannel;
