import api from '@/api';
import React from 'react';
import moment from 'moment';
import { eachDayOfInterval } from 'date-fns';
import { IAccountRoleTypeOptions } from '@/models/account/types';
import IconTextButton, {
  IIconTextButtonTypes,
  IUIIcons,
} from '@/components/ui/buttons/IconTextButton';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import styles from './styles.module.scss';
import { EMOJI_ICON_SIZE } from '@/styles/iconSizes';

const generateInterval = (rangeStartDateTime, eventEndDateTime) => {
  return eachDayOfInterval({
    start: rangeStartDateTime,
    end: eventEndDateTime,
  });
};

export const dateDisplayFormat = (date, customConfig = {}) =>
  moment(date).calendar({
    sameDay: '[Today]',
    nextDay: 'Do MMM',
    nextWeek: 'Do MMM',
    lastDay: 'Do MMM',
    lastWeek: 'Do MMM',
    sameElse: 'Do MMM',
    ...customConfig,
  });

export const getDatesAvailability = async (event, details) => {
  let dateOptions: any[] = [];
  const dateTimeOptions: any[] = [];
  const currentDateTime = new Date();
  const eventStartDateTime = new Date(event.startDateTime);
  const eventEndDateTime = new Date(event.endDateTime);

  const rangeStartDateTime =
    eventStartDateTime > currentDateTime ? eventStartDateTime : currentDateTime;
  try {
    const {
      data,
    } = await api.accessGroups.getAvailableDatesListForMeetingSetup(
      event.eventId,
      details.accountId,
    );
    data.forEach(obj => {
      const accessDate = new Date(obj.accessDate);
      accessDate.setHours(0, 0, 0, 0);
      currentDateTime.setHours(0, 0, 0, 0);
      if (accessDate >= currentDateTime && obj.isActive === true) {
        dateOptions.push(accessDate);
      }
    });
  } catch {
    // display all dates incase the request fails
    dateOptions = generateInterval(rangeStartDateTime, eventEndDateTime);
  }
  dateOptions?.reverse().forEach(date => {
    const dateTimeOption = {
      label: dateDisplayFormat(new Date(date.toJSON())),
      value: date,
    };
    dateTimeOptions.push(dateTimeOption);
  });

  return dateTimeOptions;
};

export const getPreviewProfileModalView = (handleCloseModal: any) => {
  const headerView = (
    <div className={styles.profilePreviewModalHeaderView}>
      <Text
        text="Here is a preview of your profile. Event participants can view your profile to network"
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.DEFAULT}
        textColor={ITextColors.MONOCHROME_CONTRAST}
        textLineHeight="26px"
      />
    </div>
  );

  const footerView = (
    <div>
      <IconTextButton
        activeIcon={IUIIcons.CLOSE_CIRCLE_FILLED}
        defaultIcon={IUIIcons.CLOSE_CIRCLE_FILLED}
        iconSize={EMOJI_ICON_SIZE}
        defaultLabel="Close preview"
        activeLabel="Close preview"
        showDefault
        buttonType={IIconTextButtonTypes.COLOURS_MONOCHROME_CONTRAST}
        onClick={handleCloseModal}
        style={{
          height: '50px',
          width: '185px',
          borderRadius: '100px',
          backgroundColor: '#ffffff',
          cursor: 'pointer',
          fontSize: '14px',
        }}
      />
    </div>
  );

  return { headerView, footerView };
};
