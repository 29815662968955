import { selectCurrentUser } from '@/models/account';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttendeeChannelEvent } from '@/types/messaging';
import { IShowFloatingToast } from '@/components/FloatingToast/useFloatingToast';
import { useLatest } from 'react-use';
import api from '@/api';

interface IRemoteUserRequestPayload {
  name: string;
}

const getPusherEvent = (pusherEvent: string, accountId: string) =>
  `${pusherEvent}-${accountId}`;

export const useRemoteStreamHandler = ({
  localAvConfig,
  attendeeChannel,
  stopScreenShare,
  showFloatingToast,
}: {
  localAvConfig: any;
  attendeeChannel: any;
  stopScreenShare: any;
  showFloatingToast: (toast: IShowFloatingToast) => void;
}) => {
  const _localAvConfig = useLatest(localAvConfig);
  const { accountId } = useSelector(selectCurrentUser) as any;

  /**
   * Effect to handle mute/unmute-request event
   */
  useEffect(() => {
    if (!attendeeChannel) {
      return undefined;
    }
    const muteRemoteUserEvent = getPusherEvent(
      AttendeeChannelEvent.MUTE_REMOTE_USER,
      accountId,
    );
    const unmuteRequestEvent = getPusherEvent(
      AttendeeChannelEvent.UNMUTE_REQUEST_REMOTE_USER,
      accountId,
    );
    const muteListener = () => {
      if (!_localAvConfig.current) return;
      const { audioEnabled, onAudioToggle } = _localAvConfig.current;
      if (audioEnabled) {
        onAudioToggle();
        showFloatingToast({ type: 'MUTED_CURRENT_USER' });
      }
    };
    const unmuteRequestListener = (data: IRemoteUserRequestPayload) => {
      showFloatingToast({
        type: 'UNMUTE_REQUEST_REMOTE_USER',
        text: `${data.name} has requested you to enable your microphone`,
      });
    };
    attendeeChannel.bind(muteRemoteUserEvent, muteListener);
    attendeeChannel.bind(unmuteRequestEvent, unmuteRequestListener);
    return () => {
      attendeeChannel.unbind(muteRemoteUserEvent, muteListener);
      attendeeChannel.unbind(unmuteRequestEvent, unmuteRequestListener);
    };
  }, [accountId, attendeeChannel, showFloatingToast]);

  /**
   * Effect to handle video off/on-request event
   */
  useEffect(() => {
    if (!attendeeChannel) {
      return undefined;
    }
    const videoOffRemoteUserEvent = getPusherEvent(
      AttendeeChannelEvent.VIDEO_OFF_REMOTE_USER,
      accountId,
    );
    const videoOnRequestEvent = getPusherEvent(
      AttendeeChannelEvent.VIDEO_ON_REQUEST_REMOTE_USER,
      accountId,
    );
    const videoOffListener = () => {
      if (!_localAvConfig.current) return;
      const { videoEnabled, onVideoToggle } = _localAvConfig.current;
      if (videoEnabled) {
        onVideoToggle();
      }
      showFloatingToast({ type: 'VIDEO_OFF_CURRENT_USER' });
    };
    const videoOnRequestListener = (data: IRemoteUserRequestPayload) => {
      showFloatingToast({
        type: 'VIDEO_ON_REQUEST_REMOTE_USER',
        text: `${data.name} has requested you to turn on your camera`,
      });
    };
    attendeeChannel.bind(videoOffRemoteUserEvent, videoOffListener);
    attendeeChannel.bind(videoOnRequestEvent, videoOnRequestListener);
    return () => {
      attendeeChannel.unbind(videoOffRemoteUserEvent, videoOffListener);
      attendeeChannel.unbind(videoOnRequestEvent, videoOnRequestListener);
    };
  }, [accountId, attendeeChannel, showFloatingToast]);

  /**
   * Effect to handle remote screen-share off event
   */
  useEffect(() => {
    if (!attendeeChannel) {
      return undefined;
    }

    const stopScreenShareEvent = getPusherEvent(
      AttendeeChannelEvent.STOP_SCREEN_SHARE_REMOTE_USER,
      accountId,
    );

    const stopScreenShareHandler = () => {
      stopScreenShare();
    };

    attendeeChannel.bind(stopScreenShareEvent, stopScreenShareHandler);
    return () => {
      attendeeChannel.unbind(stopScreenShareEvent, stopScreenShareHandler);
    };
  }, [accountId, attendeeChannel]);
};

export const useRemoteStreamDispatch = ({
  channelConfig,
  showFloatingToast,
}: {
  channelConfig?: Record<string, any>;
  showFloatingToast: (toast: IShowFloatingToast) => void;
}) => {
  const dispatch = useDispatch();

  const muteRemoteUser = (accountId: string, name: string) => {
    if (!channelConfig) return;
    api.channel
      .muteRemoteUser(channelConfig.channelId, accountId)
      .then(() => {
        showFloatingToast({
          type: 'MUTE_REMOTE_USER',
          text: `${name} has been muted`,
        });
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: "Action couldn't be completed, try again" },
        });
      });
  };

  const unmuteRequestRemoteUser = (accountId: string) => {
    if (!channelConfig) return;
    api.channel
      .requestUnMuteRemoteUser(channelConfig.channelId, accountId)
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: "Action couldn't be completed, try again" },
        });
      });
  };

  const videoOffRemoteUser = (accountId: string, name: string) => {
    if (!channelConfig) return;
    api.channel
      .videoOffRemoteUser(channelConfig.channelId, accountId)
      .then(() => {
        showFloatingToast({
          type: 'VIDEO_OFF_REMOTE_USER',
          text: `${name}'s camera has been turned off`,
        });
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: "Action couldn't be completed, try again" },
        });
      });
  };

  const videoOnRequestRemoteUser = (accountId: string) => {
    if (!channelConfig) return;
    api.channel
      .requestVideoOnRemoteUser(channelConfig.channelId, accountId)
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: "Action couldn't be completed, try again" },
        });
      });
  };

  const stopRemoteUserScreenShare = (accountId: string) => {
    if (!channelConfig) return;
    api.channel
      .screenShareStopRemoteUser(channelConfig.channelId, accountId)
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Action could not be completed. Please try again',
          },
        });
      });
  };

  return {
    muteRemoteUser,
    unmuteRequestRemoteUser,
    videoOffRemoteUser,
    videoOnRequestRemoteUser,
    stopRemoteUserScreenShare,
  };
};
