interface ISmartNetworkingSettings {
  isActive: boolean;
  hasInterests: boolean;
  hasServices: boolean;
}

export interface IMySmartNetworkingProfile {
  interests: any[];
  lookingForServices: any[];
  offeringServices: any[];
}

export interface IState {
  allInterestsMap: any;
  allServicesMap: any;
  fetchedActiveNetworking: boolean;
  isNetworkingEnabled: boolean;
  fetchedSmartNetworkingSettings: boolean;
  smartNetworkingSettings: ISmartNetworkingSettings;
  myProfile: IMySmartNetworkingProfile;
  isSidePanelOpen: boolean;
  connectedProfiles5Pics: string[];
  totalConnectedProfiles: number;
  isChatEnabled: boolean;
  isSmallWindowWidth: boolean;
}

export interface IMutateContext {
  openAttendeeChat: (accountId: string) => void;
  getMySmartNetworkingProfile: () => void;
}

export enum EActionTypes {
  SET_INTERESTS = 'SET_INTERESTS',
  SET_NETWORKING_ENABLED = 'SET_NETWORKING_ENABLED',
  SET_SMART_NETWORKING_SETTINGS = 'SET_SMART_NETWORKING_SETTINGS',
  SET_MY_SMART_NETWORKING_PROFILE = 'SET_MY_SMART_NETWORKING_PROFILE',
  SET_SIDE_PANEL_OPEN = 'SET_SIDE_PANEL_OPEN',
  SET_CONNECTED_PROFILES_PICS = 'SET_CONNECTED_PROFILES_PICS',
  SET_CONNECTED_PROFILES_COUNT = 'SET_TOTAL_CONNECTED_PROFILES',
  SET_IS_CHAT_ENABLED = 'SET_IS_CHAT_ENABLED',
  SET_IS_SMALL_WINDOW_WIDTH = 'SET_IS_SMALL_WINDOW_WIDTH',
}
