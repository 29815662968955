import { useState, useMemo, useCallback, useEffect } from 'react';
import { HostChannelEvent } from '@/types/messaging';
import { makeSelectUserAccountId } from '@/models/account';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import api from '@/api';

const useMute = ({
  channelConfig,
  localClient,
  hostChannel,
  removeFloatingToastsByType,
}) => {
  const [toggleAudio, setToggleAudio] = useState(true);
  const [toggleVideo, setToggleVideo] = useState(true);

  const audioEnabled = useMemo(() => localClient.audioEnabled, [
    localClient.audioEnabled,
    toggleAudio,
  ]);
  const videoEnabled = useMemo(() => localClient.videoEnabled, [
    localClient.videoEnabled,
    toggleVideo,
  ]);

  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
  const [togglingAudio, setTogglingAudio] = useState(false);
  const onAudioToggle = useCallback(async () => {
    if(localClient.publishing) {
      console.error("debugMic > toggleAudio > toggling while publishing");
      return;
    }
    setTogglingAudio(true);
    await localClient.toggleAudio();
    setTogglingAudio(false);
    // Remove existing toasts when user unmute themselves
    if (!audioEnabled && removeFloatingToastsByType) {
      removeFloatingToastsByType([
        'MUTED_CURRENT_USER',
        'UNMUTE_REQUEST_REMOTE_USER',
      ]);
    }
    setToggleAudio(!toggleAudio);
  }, [audioEnabled, localClient, removeFloatingToastsByType, toggleAudio]);

  const [togglingVideo, setTogglingVideo] = useState(false);
  const onVideoToggle = useCallback(async () => {
    if(localClient.publishing) {
      console.error("debugCam > toggleVideo > toggling while publishing");
      return;
    }
    setTogglingVideo(true);
    await localClient.toggleVideo();
    setTogglingVideo(false);
    // Remove existing toasts when user turn on their video
    if (!videoEnabled && removeFloatingToastsByType) {
      removeFloatingToastsByType([
        'VIDEO_OFF_CURRENT_USER',
        'VIDEO_ON_REQUEST_REMOTE_USER',
      ]);
    }
    setToggleVideo(!toggleVideo);
  }, [localClient, removeFloatingToastsByType, toggleVideo, videoEnabled]);

  const muteAudio = async () => {
    if(localClient.publishing) {
      console.error("debugMic > muteAudio > muting while publishing");
      return;
    }
    setTogglingAudio(true);
    await localClient.muteAudio();
    setTogglingAudio(false);
    setToggleAudio(!toggleAudio);
  };

  const unmuteAudio = async () => {
    if(localClient.publishing) {
      console.error("debugMic > unmuteAudio > unmuting while publishing");
      return;
    }
    setTogglingAudio(true);
    await localClient.unmuteAudio();
    setTogglingAudio(false);
    setToggleAudio(!toggleAudio);
  };

  const disableVideo = async () => {
    if (localClient.publishing) {
      console.error("debugMic > disableVideo > disableVideo while publishing");
      return;
    }
    setTogglingVideo(true);
    await localClient.disableVideo();
    setTogglingVideo(false);
    setToggleVideo(false);
  };

  useEffect(() => {
    if (!hostChannel) {
      return;
    }

    const muteListener = async data => {
      const { accountId, channelId, channelName, uid } = JSON.parse(data);
      console.log('debugMute > muteListener', data);
      if (accountId != currentUserAccountId) {
        return;
      }
      if (!channelConfig || channelId !== channelConfig.channelId) {
        return;
      }
      await muteAudio();
    };
    hostChannel.bind(HostChannelEvent.MUTE_USER, muteListener);

    return () => {
      hostChannel.unbind(HostChannelEvent.MUTE_USER, muteListener);
    };
  }, [hostChannel]);

  return {
    audioEnabled,
    videoEnabled,
    togglingAudio,
    onAudioToggle,
    togglingVideo,
    onVideoToggle,
    muteAudio,
    disableVideo,
  };
};

export default useMute;
