import React from 'react';
import classnames from 'classnames';

import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';

import styles from './styles.module.scss';
import { IDateListProps } from './types';

const DateList = (props: IDateListProps) => {
  const { options, selected, onClick } = props;
  return (
    <div className={styles.container}>
      {options.map(option => (
        <div
          className={classnames(styles.date, {
            [styles.activeDate]: option.value === selected,
          })}
          onClick={() => onClick(option.value)}
        >
          <Text
            text={option.label}
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={ITextSizes.MEDIUM}
            textStyle={ITextStyles.BOLDED}
            textLineHeight="22px"
          />
        </div>
      ))}
    </div>
  );
};

export default DateList;
