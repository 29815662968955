import React, { useContext } from 'react';
import classnames from 'classnames';

import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { getCssVar } from '@/utils/cssVars';
import ProfilePicture from '@/components/ui/ProfilePicture';
import { getFullName } from '@/utils/helpers';
import { IContentColors, IUIIcons } from '@/types';
import { ITicketTagTypes } from '@/types/tickets';
import DoubleTickIcon from '@/components/ui/new-icons/DoubleTick';
import FacebookNegative from '@/components/ui/new-icons/FacebookNegative';
import LinkedinNegative from '@/components/ui/new-icons/LinkedinNegative';
import TwitterNegative from '@/components/ui/new-icons/TwitterNegative';
import Web from '@/components/ui/new-icons/Web';
import { IAccountRoleTypeOptions } from '@/models/account/types';
import { SmartNetworkingContext } from '../../context';
import styles from './styles.module.scss';
import { ESocialMediaTypes, IProfileDetailsProps } from './types';
import MapPinUserFill from '@/components/ui/new-icons/MapPinUserFill';
import ComputerLine from '@/components/ui/new-icons/ComputerLine';

const SocialLinkIconMap = {
  [ESocialMediaTypes.FACEBOOK]: FacebookNegative,
  [ESocialMediaTypes.LINKEDIN]: LinkedinNegative,
  [ESocialMediaTypes.TWITTER]: TwitterNegative,
  [ESocialMediaTypes.WEBSITE]: Web,
};

const DetailsView = (props: IProfileDetailsProps) => {
  const {
    firstName,
    lastName,
    picUrl,
    designation,
    company,
    bio,
    socialLinks,
    interests,
    onClickSchenduleMeeting,
    onClickInbox,
    ticketTagType,
    showTicketTagType,
    hideScheduleAndChat = false,
    eventRoleType,
    meetingIntervalTimeObj,
    isInvokedByProfilePreview = false,
    isInterestsEnabledByOrganizer = false,
    showChatButton,
  } = props;

  const {
    allInterestsMap,
    myProfile: { interests: myInterests },
    isChatEnabled,
    smartNetworkingSettings,
  } = useContext(SmartNetworkingContext);
  const fullName = getFullName(firstName, lastName);

  let { hasInterests } = smartNetworkingSettings;
  if (isInvokedByProfilePreview) {
    hasInterests = isInterestsEnabledByOrganizer;
  }
  const interestsLoading = !interests;

  const InterestLoader = [1, 2, 3, 4, 5].map(v => (
    <div key={`${v}`} className={styles.interestLoader} />
  ));

  const showInterestsEmptyState = interests && interests.length === 0;
  const noInterestsTextContent = isInvokedByProfilePreview
    ? 'Your interests will be visible here'
    : `${fullName} has not added any interests yet`;
  const NoInterestsAvailable = (
    <div className={styles.interestContentUnavailable}>
      <Text
        text={noInterestsTextContent}
        textColor={ITextColors.NEUTRAL_DARKEST_3}
        textLineHeight="24px"
      />
    </div>
  );

  const noBioTextContent = isInvokedByProfilePreview
    ? 'Your bio will be visible here'
    : `${fullName} has not added bio yet`;

  const areSocialLinksAvailable = Boolean(socialLinks?.length > 0);
  const allowedSocialLinkTypes = Object.values(ESocialMediaTypes);

  const goToSocialLink = (url: string) =>
    window.open(url.match(/^https?:\/\//i) ? url : `http://${url}`, '_blank');

  const doesTicketAndRoleTypeMatch =
    ticketTagType &&
    ticketTagType !== ITicketTagTypes.HYBRID &&
    eventRoleType !== IAccountRoleTypeOptions.ORGANIZER;

  return (
    <div className={styles.container}>
      <div className={styles.wrapperContainer}>
        <div className={styles.headerWrapper}>
          <div className={styles.aboutWrapper}>
            <div className={styles.profilePicWrapper}>
              <ProfilePicture
                name={fullName}
                imageUrl={picUrl}
                styleClass="profilePic"
                disableTooltip
                hexColor={getCssVar(IContentColors.PRIMARY)}
                isLive={false}
              />
            </div>
            <div className={styles.detailsWrapper}>
              <Text
                text={fullName}
                textSize={ITextSizes.MEDIUM}
                textColor={ITextColors.MONOCHROME_CONTRAST}
                textLineHeight="21px"
                className={classnames(styles.singleLineText, styles.name)}
              />
              {designation && (
                <Text
                  text={designation}
                  textSize={ITextSizes.SMALL}
                  textColor={ITextColors.NEUTRAL_DARK}
                  textLineHeight="21px"
                  className={classnames(
                    styles.singleLineText,
                    styles.designation,
                  )}
                />
              )}
              {company && (
                <Text
                  text={`@ ${company}`}
                  textSize={ITextSizes.SMALL}
                  textColor={ITextColors.NEUTRAL_DARK}
                  textLineHeight="21px"
                  className={classnames(styles.singleLineText, styles.company)}
                />
              )}
            </div>
          </div>
          {showTicketTagType && doesTicketAndRoleTypeMatch && (
            <div className={styles.typeWrapper}>
              <div className={styles.iconWrapper}>
                {ticketTagType === ITicketTagTypes.VIRTUAL ? (
                  <ComputerLine
                    color={IContentColors.NEUTRAL_CONTRAST_1}
                    size={14}
                  />
                ) : (
                  <MapPinUserFill color={IContentColors.WHITE} size={14} />
                )}
              </div>
              <span className={styles.typeText}>
                Attending&nbsp;
                <span className={styles.type}>
                  {ticketTagType === ITicketTagTypes.VIRTUAL
                    ? 'Virtually'
                    : 'In Person'}
                </span>
              </span>
            </div>
          )}
          {areSocialLinksAvailable && (
            <div
              className={classnames(styles.socialLinksWrapper, {
                [styles.socialLinksWrapperStandalone]: !showTicketTagType,
              })}
            >
              {socialLinks?.map(socialLink => {
                const Icon = SocialLinkIconMap[socialLink.type];
                if (
                  !allowedSocialLinkTypes.includes(socialLink.type) ||
                  !socialLink.url
                ) {
                  return null;
                }
                return (
                  <div
                    className={styles.socialLink}
                    onClick={() => goToSocialLink(socialLink.url)}
                    aria-hidden
                  >
                    <Icon size={18} color={IContentColors.MONOCHROME_CONTRAST} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {hasInterests && (
          <div className={styles.interestsWrapper}>
            <div className={styles.interestText}>
              <Text
                text="Interests"
                textSize={ITextSizes.MEDIUM}
                textStyle={ITextStyles.BOLDED_CAPITALIZED}
                textColor={ITextColors.NEUTRAL_MEDIUM_2}
                textLineHeight="19px"
              />
            </div>
            {!isInvokedByProfilePreview && (
              <div className={styles.interestList}>
                {interestsLoading && InterestLoader}
                {interests?.map(interest => (
                  <div key={`${interest}`} className={styles.interest}>
                    <Text
                      text={allInterestsMap[interest]?.interestName}
                      textSize={ITextSizes.MEDIUM}
                      textColor={ITextColors.MONOCHROME_CONTRAST}
                      textLineHeight="17px"
                      className={styles.text}
                    />
                    {myInterests.includes(interest) && (
                      <div className={styles.interestMatch}>
                        <DoubleTickIcon
                          color={IContentColors.COLORS_BRIGHT_GREEN}
                          size={14}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {showInterestsEmptyState && NoInterestsAvailable}
              </div>
            )}
            {isInvokedByProfilePreview && (
              <div className={styles.interestList}>
                {showInterestsEmptyState && NoInterestsAvailable}
                {interests?.map(interest => (
                  <div key={`${interest}`} className={styles.interest}>
                    <Text
                      text={interest.interestName}
                      textSize={ITextSizes.MEDIUM}
                      textColor={ITextColors.MONOCHROME_CONTRAST}
                      textLineHeight="17px"
                      className={styles.text}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className={styles.bioWrapper}>
          <div className={styles.bioText}>
            <Text
              text="Bio"
              textSize={ITextSizes.MEDIUM}
              textStyle={ITextStyles.BOLDED_CAPITALIZED}
              textColor={ITextColors.NEUTRAL_MEDIUM_2}
              textLineHeight="19px"
            />
          </div>
          <div className={styles.bioContent}>
            <div className={styles.bioContentAvailable}>
              {bio && (
                <Text
                  text={bio}
                  textColor={ITextColors.MONOCHROME_CONTRAST}
                  textLineHeight="19px"
                />
              )}
            </div>
            {!bio && (
              <div className={styles.bioContentUnavailable}>
                <Text
                  text={noBioTextContent}
                  textColor={ITextColors.NEUTRAL_DARKEST_3}
                  textLineHeight="19px"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isInvokedByProfilePreview && (
        <div className={styles.ctaWrapper}>
          {meetingIntervalTimeObj && (
            <div
              className={classnames(styles.scheduleMeetingCTA, {
                [styles.onlyScheduleMeetingIsEnabled]: !isChatEnabled,
              })}
            >
              <IconTextButton
                activeIcon={IUIIcons.FEATHER_CALENDAR}
                defaultIcon={IUIIcons.FEATHER_CALENDAR}
                defaultLabel="Schedule a Meeting"
                activeLabel="Schedule a Meeting"
                onClick={onClickSchenduleMeeting}
                showDefault
                buttonType={IIconTextButtonTypes.NEW_PRIMARY}
                iconSize={20}
                style={{ height: '48px' }}
                disabled={hideScheduleAndChat}
              />
            </div>
          )}
          {isChatEnabled && showChatButton && (
            <div className={styles.chatCTA}>
              <IconTextButton
                activeIcon={IUIIcons.FEATHER_MESSAGE_CIRCLE}
                defaultIcon={IUIIcons.FEATHER_MESSAGE_CIRCLE}
                defaultLabel="Chat"
                activeLabel="Chat"
                onClick={onClickInbox}
                showDefault
                buttonType={IIconTextButtonTypes.COLOURS_NEUTRAL_MID_1}
                iconSize={20}
                style={{ height: '48px' }}
                disabled={hideScheduleAndChat}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsView;
