import { createContext } from 'react';
import { IState, IMutateContext, EActionTypes } from './context.types';

export const initialState = {
  allInterestsMap: {},
  allServicesMap: {},
  myProfile: {
    interests: [],
    lookingForServices: [],
    offeringServices: [],
  },
  isNetworkingEnabled: false,
  fetchedActiveNetworking: false,
  smartNetworkingSettings: {
    isActive: false,
    hasInterests: false,
    hasServices: false,
  },
  fetchedSmartNetworkingSettings: false,
  isSidePanelOpen: false,
  connectedProfiles5Pics: [],
  totalConnectedProfiles: 0,
  isChatEnabled: false,
  isSmallWindowWidth: true,
} as IState;

export function smartNetworkingReducer(state: IState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case EActionTypes.SET_INTERESTS: {
      const allInterestsMap = payload.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.interestId]: curr,
        }),
        {},
      );
      return {
        ...state,
        allInterestsMap,
      };
    }
    case EActionTypes.SET_NETWORKING_ENABLED: {
      return {
        ...state,
        isNetworkingEnabled: payload,
        fetchedActiveNetworking: true,
      };
    }
    case EActionTypes.SET_SMART_NETWORKING_SETTINGS: {
      const { isActive, hasInterests, hasServices } = payload;
      return {
        ...state,
        smartNetworkingSettings: {
          isActive,
          hasInterests,
          hasServices,
        },
        fetchedSmartNetworkingSettings: true,
      };
    }
    case EActionTypes.SET_MY_SMART_NETWORKING_PROFILE: {
      const { interests, lookingForServices, offeringServices } = payload;
      return {
        ...state,
        myProfile: {
          interests,
          lookingForServices,
          offeringServices,
        },
      };
    }
    case EActionTypes.SET_SIDE_PANEL_OPEN: {
      return {
        ...state,
        isSidePanelOpen: payload,
      };
    }
    case EActionTypes.SET_CONNECTED_PROFILES_PICS: {
      return {
        ...state,
        connectedProfiles5Pics: payload,
      };
    }
    case EActionTypes.SET_CONNECTED_PROFILES_COUNT: {
      return {
        ...state,
        totalConnectedProfiles: payload,
      };
    }
    case EActionTypes.SET_IS_CHAT_ENABLED: {
      return {
        ...state,
        isChatEnabled: payload,
      };
    }
    case EActionTypes.SET_IS_SMALL_WINDOW_WIDTH: {
      return {
        ...state,
        isSmallWindowWidth: payload,
      };
    }
    default: {
      return state;
    }
  }
}

export { EActionTypes, IMutateContext };

export const SmartNetworkingContext = createContext(initialState);
export const SmartNetworkingMutateContext = createContext({} as IMutateContext);
