import React, { createContext, memo, useContext } from 'react';
import * as PusherTypes from 'pusher-js';
import { useVirtualBackground } from '@/hooks/use-virtual-background';
import useChannelRequest from '@/services/messaging/use-channel-request';
import useFloatingToast from '@/components/FloatingToast/useFloatingToast';

interface IChannelContext {
  attendeeChannel?: PusherTypes.Channel;
  hostChannel?: PusherTypes.Channel;
  floatingToast?: ReturnType<typeof useFloatingToast>;
  virtualBackground?: ReturnType<typeof useVirtualBackground>;
  channelRequest: ReturnType<typeof useChannelRequest>;
  channelConfig?: Record<string, any>;
  channelSettings?: Record<string, any>;
}

interface Props extends IChannelContext {
  children: React.ReactNode;
}

const ChannelContext = createContext<IChannelContext>({
  channelRequest: {
    hasRaisedHand: false,
    raiseHandRequest: async () => undefined,
    lowerHandRequest: async () => undefined,
    lowerHandOfOther: async () => undefined,
    raisedHandRequestList: [],
  },
});

const ChannelContextProvider = memo<Props>(
  ({
    children,
    attendeeChannel,
    hostChannel,
    floatingToast,
    virtualBackground,
    channelRequest,
    channelConfig,
    channelSettings,
  }) => (
    <ChannelContext.Provider
      value={{
        hostChannel,
        attendeeChannel,
        floatingToast,
        virtualBackground,
        channelRequest,
        channelConfig,
        channelSettings,
      }}
    >
      {children}
    </ChannelContext.Provider>
  ),
);

export function useChannelContext() {
  return useContext(ChannelContext);
}

export default ChannelContextProvider;
