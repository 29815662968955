import shortid from 'shortid';
import { _isObject } from './jsHelpers/obj';
import { _isArray } from './jsHelpers/array';

const addUidToObj = (obj) => {
  if (!_isObject(obj)) {
    return obj;
  }
  return {
    uid: shortid.generate(),
    ...obj
  };
};

const addUidsToArray = (arr) => {
  // Empty
  if (arr.length === 0) {
    return arr;
  }

  const firstElem = arr[0];
  // arr is an array of arrays
  if (Array.isArray(firstElem)) {
    return arr;
  };

  // arr is an array of objects
  if (_isObject(firstElem)) {
    return arr.map(t => addUidToObj(t));
  }

  // arr is an array of primitives
  return arr.map(t => {
    return t && {
      uid: shortid.generate(),
      value: t
    };
  });
}

export const addUids = (thing) => {
  if (!thing) {
    return thing;
  }

  // Not an array or an object i.e. some primitive
  if (!(typeof thing === 'object')) {
    return thing;
  }

  // Array
  if (Array.isArray(thing)) {
    return addUidsToArray(thing);
  }

  // Object
  return addUidToObj(thing);
};

export const getUid = () => shortid.generate();