import { useEffect, useMemo } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
// services
import { useVideoContext } from './video-context';
import isEmpty from 'lodash/isEmpty';
import { getMediaDeviceBrowserData } from '@/components/ui/modals/StreamPreviewModal/utils';

export default function useDevices(
  findDevices,
  deps: any = [],
  getDevicesFailedCallback?: (err) => void,
) {
  const [stateCtx, mutationCtx] = useVideoContext() as any;

  const [cameraList, microphoneList, speakerList] = useMemo(
    () => [
      stateCtx.devicesList
        .filter(item => item.kind === 'videoinput')
        .map((item, idx) => ({
          value: item.deviceId,
          label: item.label ? item.label : `Video Input ${idx}`,
        })),
      stateCtx.devicesList
        .filter(item => item.kind === 'audioinput')
        .map((item, idx) => ({
          value: item.deviceId,
          label: item.label ? item.label : `Audio Input ${idx}`,
        })),
      stateCtx.devicesList
        .filter(item => item.kind === 'audiooutput')
        .map((item, idx) => ({
          value: item.deviceId,
          label: item.label ? item.label : `Audio Output ${idx}`,
        })),
    ],
    [stateCtx.devicesList],
  );

  const getDevicesWithRetry = async (fn, maxAttempts) => {
    const execute = async attempt => {
      try {
        return await fn();
      } catch (_err) {
        if (attempt < maxAttempts) {
          const nextAttempt = attempt + 1;
          return execute(nextAttempt);
        }
        if (attempt === maxAttempts) {
          const nextAttempt = attempt + 1;
          console.error(
            'useDevices > error fetching devices list, forcing browser permissions popup',
          );
          try {
            await getMediaDeviceBrowserData();
            return execute(nextAttempt);
          } catch (err) {
            console.error('useDevices > browser media permissions denied');
          }
        }
        console.error('useDevices > failed fetching devices', _err);
        mutationCtx.setDevicesList([]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        getDevicesFailedCallback && getDevicesFailedCallback(_err);
      }
      return undefined;
    };

    return execute(0);
  };

  useEffect(() => {
    if (!findDevices) {
      return;
    }

    // On browsers including Chrome 81 or later, Firefox, and Safari, the SDK cannot get accurate device
    // information without permission for the media device.
    // https://docs.agora.io/en/All/API%20Reference/web_ng/v4.9.0/interfaces/iagorartc.html#getdevices
    const getDevices = async () => {
      const deviceList = (await AgoraRTC.getDevices()).filter(device =>
        Boolean(device.deviceId),
      );
      // Check if devices list is empty in certain conditions on mobile devices
      if (isEmpty(deviceList)) {
        throw new Error();
      }
      // Safari doesn't support navigator's enumerateDevices API to get the default speakers.
      // They also don't have the ability to switch audio output!
      if (!deviceList.some(device => device.kind === 'audiooutput')) {
        deviceList.push({
          deviceId: 'default',
          kind: 'audiooutput',
          label: 'System Default Speaker Device',
          groupId: '',
          toJSON: () => {},
        });
      }

      mutationCtx.setDevicesList(deviceList);
    };

    // Retry to prevent getDevices to fail when browser permissions are not given
    getDevicesWithRetry(getDevices, 2);
  }, [findDevices, ...deps]);

  useEffect(() => {
    if (
      cameraList[0] &&
      microphoneList[0] &&
      speakerList[0] &&
      (!stateCtx.main.config.cameraId ||
        !stateCtx.main.config.microphoneId ||
        !stateCtx.main.config.speakerId)
    ) {
      const savedCameraId = localStorage.getItem('selectedCamera');
      const isSavedCameraAvailable = cameraList.some(
        camera => camera.value === savedCameraId,
      );

      const savedMicrophoneId = localStorage.getItem('selectedMicrophone');
      const isSavedMicrophoneAvailable = microphoneList.some(
        microphone => microphone.value === savedMicrophoneId,
      );

      const savedSpeakerId = localStorage.getItem('selectedPlaybackDevice');
      const isSavedSpeakerAvailable = speakerList.some(
        speaker => speaker.value === savedSpeakerId,
      );

      mutationCtx.updateMainConfig({
        cameraId: isSavedCameraAvailable ? savedCameraId : cameraList[0].value,
        microphoneId: isSavedMicrophoneAvailable
          ? savedMicrophoneId
          : microphoneList[0].value,
        speakerId: isSavedSpeakerAvailable
          ? savedSpeakerId
          : speakerList[0].value,
      });
    }
  }, [
    stateCtx.devicesList,
    stateCtx.main.config,
    cameraList,
    microphoneList,
    speakerList,
  ]);
  // TODO (Agora): what to do when the devices change and the stream is already published

  return [
    cameraList,
    microphoneList,
    speakerList,
    stateCtx.main.config.cameraId,
    stateCtx.main.config.microphoneId,
    stateCtx.main.config.speakerId,
  ];
}
