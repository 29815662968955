import React from "react";

export const CrossIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99999 3.93949L8.71249 0.22699L9.77299 1.28749L6.06049 4.99999L9.77299 8.71249L8.71249 9.77299L4.99999 6.06049L1.28749 9.77299L0.22699 8.71249L3.93949 4.99999L0.22699 1.28749L1.28749 0.22699L4.99999 3.93949Z" fill="#CDCDCD" />
    </svg>
)

export const CheckIcon = () => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00002 12.3334C2.77827 12.3334 0.166687 9.72177 0.166687 6.50002C0.166687 3.27827 2.77827 0.666687 6.00002 0.666687C9.22177 0.666687 11.8334 3.27827 11.8334 6.50002C11.8334 9.72177 9.22177 12.3334 6.00002 12.3334ZM5.41844 8.83335L9.5426 4.7086L8.71777 3.88377L5.41844 7.18369L3.76819 5.53344L2.94335 6.35827L5.41844 8.83335Z" fill="#77B903" />
    </svg>
)

export const CollapseIcon = () => (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.50002 1.7675L1.40627 4.86125L0.522522 3.9775L4.50002 0L8.47752 3.9775L7.59377 4.86125L4.50002 1.7675Z" fill="#727272" />
    </svg>
)

export const ExpandIcon = () => (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.50002 3.23248L7.59377 0.138733L8.47752 1.02248L4.50002 4.99998L0.522522 1.02248L1.40627 0.138733L4.50002 3.23248Z" fill="#727272" />
    </svg>
)

export const RightArrowIcon = () => (
    <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.23248 4.50002L0.138733 1.40627L1.02248 0.522522L4.99998 4.50002L1.02248 8.47752L0.138733 7.59377L3.23248 4.50002Z" fill="#727272" />
    </svg>
)

export const CircleIcon = () => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00002 12.3334C2.77827 12.3334 0.166687 9.72177 0.166687 6.50002C0.166687 3.27827 2.77827 0.666687 6.00002 0.666687C9.22177 0.666687 11.8334 3.27827 11.8334 6.50002C11.8334 9.72177 9.22177 12.3334 6.00002 12.3334ZM6.00002 11.1667C7.2377 11.1667 8.42468 10.675 9.29985 9.79985C10.175 8.92468 10.6667 7.7377 10.6667 6.50002C10.6667 5.26234 10.175 4.07536 9.29985 3.20019C8.42468 2.32502 7.2377 1.83335 6.00002 1.83335C4.76234 1.83335 3.57536 2.32502 2.70019 3.20019C1.82502 4.07536 1.33335 5.26234 1.33335 6.50002C1.33335 7.7377 1.82502 8.92468 2.70019 9.79985C3.57536 10.675 4.76234 11.1667 6.00002 11.1667Z" fill="#77B903" />
    </svg>
)