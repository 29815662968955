/* eslint-disable no-param-reassign */
import produce from 'immer';
import { StreamType } from '@/components/Stage/types';
import zuddlLogger from '@/utils/zuddlLogger';
import { _DIAL_IN_UIDS } from '@/config/vj';
import { IVideoState, TReducerAction } from './video-state.types';
import isEqual from 'lodash/isEqual';

const defaultState: IVideoState = {
  connectionState: {
    previousState: undefined,
    state: undefined,
    reason: '',
  },
  mainReady: false,
  greenRoomReady: false,
  audioTrackMap: {},
  videoTrackMap: {},
  streamIds: [],
  dialInIds: [],
  greenRoomStreamIds: [],
  streamInfoMap: {},
  streamJoinTimeMap: {},
  greenRoomJoinTimeMap: {},
  streamsSubscribed: {audio: new Set(), video: new Set()},  // Not using greenRoom streams for now
  greenRoomStreamsSubscribed: {audio: new Set(), video: new Set()},
  streamPagination: {
    currentPage: 1, // Default page
    perPage: 1, // No pagination
    playSiblingsCount: 1, // How many adjacent page's videos should play, audio always plays
    order: [],
  },
  talkingUsers: [],
  localStreamId: null,
  screenShareStreamId: null,
  primaryStreamId: null,
  devicesList: [],
  loading: false,
  stats: {
    downlinkNetworkQuality: 0,
    uplinkNetworkQuality: 0,
  },
  main: {
    published: false,
    client: null,
    config: {
      uid: 0,
      host: false,
      channelName: '',
      token: null,
      microphoneId: '',
      cameraId: '',
      speakerId: '',
    },
    videoOn: true,
    audioOn: true,
    profile: false,
  },
  screen: {
    loading: false,
    published: false,
    client: null,
    config: {
      uid: 0,
      host: false,
      channelName: '',
      token: null,
    },
    screenAudio: false,
    profile: false,
  },
  mode: 'live',
  codec: 'vp8',
};

const isScreenStream = streamInfo =>
  streamInfo &&
  [StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(streamInfo.type);

const reducer = (state: IVideoState, action: TReducerAction ) => {
  switch (action.type) {
    case 'setDevicesList': {
      return {
        ...state,
        devicesList: action.payload,
      };
    }
    case 'updateMainConfig': {
      return produce(state, draft => {
        draft.main.config = {
          ...draft.main.config,
          ...action.payload,
        };
      });
    }
    case 'setLocalStream': {
      const localStream = action.payload;
      return produce(state, draft => {
        const { uid: localStreamId, audioTrack, videoTrack } = localStream;
        const currentTime = -1;
        draft.localStreamId = localStreamId;
        if (audioTrack) {
          draft.audioTrackMap[localStreamId] = audioTrack;
          draft.streamJoinTimeMap[`${localStreamId}-audio`] = currentTime;
        }
        if (videoTrack) {
          const existingVideo = draft.videoTrackMap[localStreamId];
          if (existingVideo && existingVideo.isPlaying) {
            existingVideo.stop();
            existingVideo.close();
          }

          draft.videoTrackMap[localStreamId] = videoTrack;
          draft.streamJoinTimeMap[`${localStreamId}-video`] = currentTime;
        }
        draft.streamJoinTimeMap[localStreamId] = currentTime;
      });
    }
    case 'switchCamera': {
      const cameraId = action.payload;
      return produce(state, draft => {
        draft.main.config.cameraId = cameraId;
      });
    }
    case 'switchMicrophone': {
      const microphoneId = action.payload;
      return produce(state, draft => {
        draft.main.config.microphoneId = microphoneId;
      });
    }
    case 'switchSpeaker': {
      const speakerId = action.payload;
      return produce(state, draft => {
        draft.main.config.speakerId = speakerId;
      });
    }
    case 'clearLocalStream': {
      return produce(state, draft => {
        const localStreamId = draft.localStreamId;
        if (localStreamId) {
          delete draft.audioTrackMap[localStreamId];
          delete draft.videoTrackMap[localStreamId];
        }
        draft.streamIds = draft.streamIds.filter(id => id !== localStreamId);

        draft.greenRoomStreamIds = draft.greenRoomStreamIds.filter(id => id !== localStreamId);
        if (draft.primaryStreamId === localStreamId) {
          if (draft.streamIds && draft.streamIds.length > 0) {
            draft.primaryStreamId = draft.streamIds[0];
          } else {
            draft.primaryStreamId = null;
          }
        }
        draft.localStreamId = null;
      });
    }
    case 'publishLocalStream': {
      return produce(state, draft => {
        const { localStreamId, primaryStreamId, streamInfoMap } = draft;
        if (localStreamId) {
          if (!draft.streamIds.includes(localStreamId)) {
            draft.streamIds.unshift(localStreamId);
          }
          const primaryStreamInfo = streamInfoMap[primaryStreamId];
          if (!primaryStreamInfo || !isScreenStream(primaryStreamInfo)) {
            draft.primaryStreamId = localStreamId;
          }
        }
      });
    }
    case 'switchLocalStreamUid': {
      const { uid } = action.payload;
      if (!uid) {
        return state;
      }
      return produce(state, draft => {
        const { localStreamId } = draft;
        if (!localStreamId) {
          return;
        }
        draft.localStreamId = uid;
        const audioTrack = draft.audioTrackMap[localStreamId];
        if (audioTrack) {
          draft.audioTrackMap[uid] = audioTrack;
          delete draft.audioTrackMap[localStreamId];
        }
        const videoTrack = draft.videoTrackMap[localStreamId];
        if (videoTrack) {
          draft.videoTrackMap[uid] = videoTrack;
          delete draft.videoTrackMap[localStreamId];
        }
      });
    }
    case 'publishLocalStreamGreenRoom': {
      return produce(state, draft => {
        const { localStreamId } = draft;
        if (localStreamId) {
          if (!draft.greenRoomStreamIds.includes(localStreamId)) {
            draft.greenRoomStreamIds.unshift(localStreamId);
          }
        }
      });
    }
    case 'unpublishLocalStream': {
      return produce(state, draft => {
        const { localStreamId } = draft;

        if (localStreamId) {
          draft.streamIds = draft.streamIds.filter(id => id !== localStreamId);

          draft.greenRoomStreamIds = draft.greenRoomStreamIds.filter(
            id => id !== localStreamId,
          );

          if (draft.primaryStreamId === localStreamId) {
            if (draft.streamIds && draft.streamIds.length > 0) {
              draft.primaryStreamId = draft.streamIds[0];
            } else {
              draft.primaryStreamId = null;
            }
          }
        }
      });
    }
    case 'clearStreamsForSwitch': {
      return produce(state, draft => {
        const {
          localStreamId,
          screenShareStreamId,
          streamIds,
          greenRoomStreamIds,
          audioTrackMap,
          videoTrackMap,
        } = draft;

        const newAudioTrackMap = {};
        const newVideoTrackMap = {};

        greenRoomStreamIds.forEach(streamId => {
          newAudioTrackMap[streamId] = audioTrackMap[streamId];
          newVideoTrackMap[streamId] = videoTrackMap[streamId];
        });

        const newStreamIds = [];
        // close all streams besides local and screenshare and greenroom
        for (const streamId in audioTrackMap) {
          if (
            ['' + localStreamId, '' + screenShareStreamId].includes(streamId) ||
            greenRoomStreamIds.includes(parseInt(streamId, 10))
          ) {
            continue;
          }

          try {
            const audioTrack = audioTrackMap[streamId];
            audioTrack.stop();
            audioTrack.close();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            continue;
          }
        }

        for (const streamId in videoTrackMap) {
          if (
            ['' + localStreamId, '' + screenShareStreamId].includes(streamId) ||
            greenRoomStreamIds.includes(parseInt(streamId, 10))
          ) {
            continue;
          }

          try {
            const videoTrack = videoTrackMap[streamId];
            videoTrack.stop();
            videoTrack.close();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            continue;
          }
        };

        if (localStreamId) {
          newAudioTrackMap[localStreamId] = audioTrackMap[localStreamId];
          newVideoTrackMap[localStreamId] = videoTrackMap[localStreamId];
          if (streamIds.includes(localStreamId)) {
            newStreamIds.push(localStreamId);
          }
        }

        if (screenShareStreamId) {
          newAudioTrackMap[screenShareStreamId] =
            audioTrackMap[screenShareStreamId];
          newVideoTrackMap[screenShareStreamId] =
            videoTrackMap[screenShareStreamId];

          if (streamIds.includes(screenShareStreamId)) {
            newStreamIds.push(screenShareStreamId);
          }
        }
        const newPrimaryStreamId = screenShareStreamId || localStreamId || null;

        draft.primaryStreamId = newPrimaryStreamId;
        draft.audioTrackMap = newAudioTrackMap;
        draft.videoTrackMap = newVideoTrackMap;
        draft.streamIds = newStreamIds;
      });
    }
    case 'addGreenRoomRemoteUser': {
      const { user, mediaType } = action.payload;
      const { uid, audioTrack, videoTrack } = user;

      return produce(state, draft => {
        if (mediaType !== 'video' && audioTrack) {
          draft.audioTrackMap[uid] = audioTrack;
          if (videoTrack && !draft.videoTrackMap[uid]) {
            draft.videoTrackMap[uid] = videoTrack;
          }
        }
        if (mediaType !== 'audio' && videoTrack) {
          draft.videoTrackMap[uid] = videoTrack;
          if (audioTrack && !draft.audioTrackMap[uid]) {
            draft.audioTrackMap[uid] = audioTrack;
          }
        }

        if (!draft.greenRoomStreamIds.includes(uid)) {
          draft.greenRoomStreamIds.unshift(uid);
        }

        // A fallback in case the addStreamPresence isn't triggered, only add if not exists
        if(!draft.greenRoomJoinTimeMap[uid]) {
          draft.greenRoomJoinTimeMap[uid] = new Date().getTime();
        }
      });
    }
    case 'removeGreenRoomRemoteUser': {
      const { user, mediaType } = action.payload;
      const { uid } = user;

      return produce(state, draft => {
        if (mediaType !== 'video') {
          delete draft.audioTrackMap[uid];
        }

        if (mediaType !== 'audio') {
          delete draft.videoTrackMap[uid];
        }
      });
    }
    case 'addRemoteUser': {
      const { user, mediaType } = action.payload;
      const { uid, audioTrack, videoTrack } = user;

      return produce(state, draft => {
        const currentTime = new Date().getTime();
        const { primaryStreamId, streamInfoMap } = draft;
        if (mediaType !== 'video' && audioTrack) {
          zuddlLogger.staging.debug('debugVideoState >> addRemoteUser >> 1', draft.audioTrackMap, uid, audioTrack);
          draft.audioTrackMap[uid] = audioTrack;
          draft.streamJoinTimeMap[`${uid}-audio`] = currentTime;
        }

        if (mediaType !== 'audio' && videoTrack) {
          zuddlLogger.staging.debug('debugVideoState >> addRemoteUser >> 2', draft.videoTrackMap, uid, audioTrack);
          draft.videoTrackMap[uid] = videoTrack;
          draft.streamJoinTimeMap[`${uid}-video`] = currentTime;
        }

        if(_DIAL_IN_UIDS.includes(uid)) {
          draft.dialInIds.unshift(uid);
          return;
        }

        const primaryStreamInfo = streamInfoMap[primaryStreamId];
        if (!draft.streamIds.includes(uid)) {
          if (!primaryStreamInfo || !isScreenStream(primaryStreamInfo)) {
            draft.primaryStreamId = uid;
          }
          draft.streamIds.unshift(uid);
        } else if(!primaryStreamInfo || !isScreenStream(primaryStreamInfo)) {
          // Since the streamIds are now being updated by addStreamPresence, it will fall
          // into this else condition and based on the existing logic, we will be setting
          // the primaryStream i.e. either no primaryStream exists or the existing primaryStream
          // is not a screen share
          draft.primaryStreamId = uid;
        }
        // A fallback in case the addStreamPresence isn't triggered, only add if not exists
        if(!draft.streamJoinTimeMap[uid]) {
          draft.streamJoinTimeMap[uid] = new Date().getTime();
        }
      });
    }
    case 'removeRemoteUser': {
      const { user, mediaType } = action.payload;
      const { uid } = user;

      return produce(state, draft => {
        if (mediaType !== 'video') {
          zuddlLogger.staging.debug('debugVideoState >> removeRemoteUser >> 1', draft.audioTrackMap, uid);
          try {
            draft.audioTrackMap[uid]?.removeAllListeners();
            draft.audioTrackMap[uid]?.stop();
          } catch (e) {
            console.error("debugVideoState > error while stopping remote audio", e);
          }
          delete draft.audioTrackMap[uid];
          delete draft.streamJoinTimeMap[`${uid}-audio`];
        }

        if (mediaType !== 'audio') {
          zuddlLogger.staging.debug('debugVideoState >> removeRemoteUser >> 2', draft.videoTrackMap, uid);
          try {
            draft.videoTrackMap[uid]?.removeAllListeners();
            draft.videoTrackMap[uid]?.stop();
          } catch (e) {
            console.error("debugVideoState > error while stopping remote video", e);
          }
          delete draft.videoTrackMap[uid];
          delete draft.streamJoinTimeMap[`${uid}-video`]
        }

        if(_DIAL_IN_UIDS.includes(uid)) {
          draft.dialInIds = draft.dialInIds.filter(id => id !== uid);

          const { primaryStreamId } = draft;
          const idx = draft.streamIds.indexOf(uid);
          if (idx > -1) {
            draft.streamIds.splice(idx, 1);
          }
          if (primaryStreamId === uid) {
            if (draft.streamIds && draft.streamIds.length > 0) {
              draft.primaryStreamId = draft.streamIds[0];
            } else {
              draft.primaryStreamId = null;
            }
          }
        }
      });
    }
    case 'addLocalScreenShare': {
      const screenStream = action.payload;

      return produce(state, draft => {
        const {
          uid: screenShareStreamId,
          audioTrack,
          videoTrack,
        } = screenStream;
        draft.screenShareStreamId = screenShareStreamId;

        if (audioTrack) {
          draft.audioTrackMap[screenShareStreamId] = audioTrack;
        }

        if (videoTrack) {
          draft.videoTrackMap[screenShareStreamId] = videoTrack;
        }

        if (screenShareStreamId) {
          draft.streamIds.unshift(screenShareStreamId);
          draft.primaryStreamId = screenShareStreamId;
        }
      });
    }
    case 'clearGreenRoomStreams': {
      return produce(state, draft => {
        // eslint-disable-next-line no-restricted-syntax
        for (const streamId of draft.greenRoomStreamIds) {
          if (streamId) {
            delete draft.audioTrackMap[streamId];
            delete draft.videoTrackMap[streamId];
          }
        }
        draft.greenRoomStreamIds = [];
      });
    }
    case 'clearScreenShare': {
      return produce(state, draft => {
        const { screenShareStreamId } = draft;
        if (screenShareStreamId) {
          delete draft.audioTrackMap[screenShareStreamId];
          delete draft.videoTrackMap[screenShareStreamId];
        }
        draft.streamIds = draft.streamIds.filter(
          id => id !== screenShareStreamId,
        );
        if (draft.primaryStreamId === screenShareStreamId) {
          if (draft.streamIds && draft.streamIds.length > 0) {
            draft.primaryStreamId = draft.streamIds[0];
          } else {
            draft.primaryStreamId = null;
          }
        }
        draft.screenShareStreamId = null;
      });
    }
    case 'setPrimaryStream': {
      const newPrimaryStream = action.payload;
      const newPrimaryStreamId = newPrimaryStream && newPrimaryStream.uid;

      return produce(state, draft => {
        draft.primaryStreamId = newPrimaryStreamId;
      });
    }
    case 'setLoading': {
      return produce(state, draft => {
        draft.loading = action.payload;
      });
    }
    case 'setNetworkQuality': {
      return produce(state, draft => {
        draft.stats = action.payload;
      });
    }
    case 'setActiveSpeaker': {
      const activeSpeaker = action.payload;

      return produce(state, draft => {
        const { primaryStreamId, streamInfoMap } = draft;
        const primaryStreamInfo = streamInfoMap[primaryStreamId];
        if (
          (!primaryStreamInfo || !isScreenStream(primaryStreamInfo)) &&
          activeSpeaker.level > 0 &&
          draft.primaryStreamId !== activeSpeaker.uid
        ) {
          draft.primaryStreamId = activeSpeaker.uid;
        }
      });
    }
    case 'setStreamInfo': {
      const streamInfoArr = action.payload;

      return produce(state, draft => {
        // eslint-disable-next-line no-restricted-syntax
        for (const streamData of streamInfoArr) {
          draft.streamInfoMap[streamData.uid] = streamData;
          if (isScreenStream(streamData)) {
            draft.primaryStreamId = streamData.uid;
          }
        }
      });
    }
    case 'updateConnectionState': {
      const { currentState, previousState, reason } = action.payload;
      return produce(state, draft => {
        draft.connectionState = {
          previousState,
          state: currentState,
          reason: reason || '',
        };
      });
    }
    case 'mainReady': {
      return produce(state, draft => {
        draft.mainReady = true;
      });
    }
    case 'greenRoomReady': {
      return produce(state, draft => {
        draft.greenRoomReady = true;
      });
    }
    case 'addStreamPresence': {
      const uid = action.payload;
      return produce(state, draft => {
        if(_DIAL_IN_UIDS.includes(uid)) {
          return;
        }
        if (!draft.streamIds.includes(uid)) {
          draft.streamIds.push(uid);
        }
      });
    }
    case 'addDialInStreamPresence': {
      const uid = action.payload;
      return produce(state, draft => {
        if(!_DIAL_IN_UIDS.includes(uid)) {
          return;
        }
        if (!draft.streamIds.includes(uid)) {
          draft.streamIds.push(uid);
        }
        // Add Joining time
        draft.streamJoinTimeMap[uid] = new Date().getTime();
      });
    }
    case 'removeStreamPresence': {
      const uid = action.payload;
      return produce(state, draft => {
        const { primaryStreamId, streamIds } = draft;
        draft.streamIds = streamIds.filter(streamId => streamId !== uid);
        if (primaryStreamId === uid) {
          if (draft.streamIds && draft.streamIds.length > 0) {
            draft.primaryStreamId = draft.streamIds[0];
          } else {
            draft.primaryStreamId = null;
          }
        }
        // Remove joining time
        delete draft.streamJoinTimeMap[uid];
      });
    }
    case 'addGreenRoomStreamPresence': {
      const uid = action.payload;
      return produce(state, draft => {
        if (!draft.greenRoomStreamIds.includes(uid)) {
          draft.greenRoomStreamIds.push(uid);
        }
        // Add Joining time
        draft.greenRoomJoinTimeMap[uid] = new Date().getTime();
      });
    }
    case 'removeGreenRoomStreamPresence': {
      const uid = action.payload;
      return produce(state, draft => {
        draft.greenRoomStreamIds = draft.greenRoomStreamIds.filter(greenRoomStreamId => greenRoomStreamId !== uid);
        delete draft.greenRoomJoinTimeMap[uid];
      });
    }
    case 'setPaginationConfig': {
      const { perPage, currentPage, playSiblingsCount, order } = action.payload;
      return produce(state, draft => {
        if(perPage > 0) {
          draft.streamPagination.perPage = perPage;
        }
        if(currentPage > 0) {
          draft.streamPagination.currentPage = currentPage;
        }
        if(playSiblingsCount >= 0) {
          draft.streamPagination.playSiblingsCount = playSiblingsCount;
        }
        if(order) {
          // making sure it actually changed, so that useEffects are not triggered unnecessarily
          if(!isEqual(draft.streamPagination.order, order)) {
            draft.streamPagination.order = order;
          }
        }
      })
    }
    case 'setTalkingUsers': {
      const users = action.payload;
      return produce(state, draft => {
        // making sure it actually changed, so that useEffects are not triggered unnecessarily
        const existingSorted = [...draft.talkingUsers].sort();
        const newSorted = [...users].sort();
        if(isEqual(existingSorted, newSorted)) return;
        draft.talkingUsers = users;
      })
    }
    case 'reset': {
      return {
        connectionState: {
          previousState: undefined,
          state: undefined,
          reason: '',
        },
        mainReady: false,
        greenRoomReady: false,
        audioTrackMap: {},
        videoTrackMap: {},
        streamIds: [],
        dialInIds: [],
        greenRoomStreamIds: [],
        streamInfoMap: {},
        streamJoinTimeMap: defaultState.streamJoinTimeMap,
        greenRoomJoinTimeMap: defaultState.greenRoomJoinTimeMap,
        streamsSubscribed: defaultState.streamsSubscribed,
        greenRoomStreamsSubscribed: defaultState.greenRoomStreamsSubscribed,
        streamPagination: defaultState.streamPagination,
        talkingUsers: defaultState.talkingUsers,
        localStreamId: null,
        screenShareStreamId: null,
        primaryStreamId: null,
        devicesList: [],
        loading: false,
        stats: {
          downlinkNetworkQuality: 0,
          uplinkNetworkQuality: 0,
        },
        main: {
          published: false,
          client: null,
          config: {
            uid: 0,
            host: false,
            channelName: '',
            token: null,
            microphoneId: '',
            cameraId: '',
            speakerId: '',
          },
          videoOn: true,
          audioOn: true,
          profile: false,
        },
        screen: {
          loading: false,
          published: false,
          client: null,
          config: {
            uid: 0,
            host: false,
            channelName: '',
            token: null,
          },
          screenAudio: false,
          profile: false,
        },
        mode: 'live',
        codec: 'vp8',
      };
    }
    default:
      throw new Error('video state > mutation type not defined');
  }
};

export { reducer, defaultState };
