import { FieldMetaState, FieldInputProps, FieldProps } from 'react-final-form';
export type IDateTimeWithTimezone = string;
export enum IFormFieldTypes {
  DATE = 'dateField',
  DATETIME = 'dateTimeField',
  FILE = 'fileField',
  IMAGE = 'imageUploadField',
  INPUT = 'inputField',
  INPUT_LIST = 'inputListField',
  MULTISELECT = 'multiSelectField',
  CHECKBOXES = 'checkBoxesField',
  PASSWORD = 'passwordField',
  SELECT = 'selectField',
  TEXTAREA = 'textAreaField',
  TIME = 'timeField',
  NUMBER = 'numberField',
  COLOR_PICKER = 'colorPicker',
  NEW_IMAGE = 'newImage',
  VIDEO = 'video',
  DOMAIN = 'domainField',
  NEW_INPUT = 'newInputField',
  COLOR_PICKER_FIELD = 'colorPickerField',
  NEW_IMAGE_UPLOAD_FIELD = 'newImageUploadField',
  NEW_PASSWORD = 'newPasswordField',
  LANDING_PAGE_INPUT = 'landingPageInput',
  NEW_SELECT = 'newSelectField',
  NEW_TEXTAREA = 'newTextAreaField',
}

export enum IFormFieldCategory {
  GENERAL = 'GENERAL',
  EVENT_SELECTION = 'EVENT_SELECTION',
}

interface ISelectFieldData {
  label: string;
  value: string;
}

export interface IFormFieldProps {
  data?: ISelectFieldData[];
  fieldType: IFormFieldTypes;
  initialState?: any;
  label: string;
  placeholder?: string;
  name: string;
  validate: any;
  widthRatio: number;
  tooltip: string;
  maxLength: number;
  selectStyleClass?: string;
  selectStyles?: any;
  hasTooltip?: boolean;
  styleClass?: string;
  readOnly?: boolean;
  dataTestId?: string;
  type?: IFormFieldTypes;
  fieldCategrory?: IFormFieldCategory;
}
export interface IFormSegmentFieldProps {
  fieldType: IFormFieldTypes;
  initialState?: any;
  label: string;
  name: string;
  validate: any;
  widthRatio: number;
  eventStartTime: IDateTimeWithTimezone;
  eventEndTime: IDateTimeWithTimezone;
}

export interface IFormFieldRow {
  fields: IFormFieldProps[] | IFormSegmentFieldProps[];
}

export interface IFieldElementProps
  extends FieldMetaState<any>,
    FieldInputProps<any> {
  fieldType: IFormFieldTypes;
  isLastElement?: boolean;
  readOnly?: boolean;
  styleClass?: string;
  widthStyles?: string;
}
