import React from 'react';
import classnames from 'classnames';

import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';

import styles from './styles.module.scss';
import { ITimeListProps } from './types';
import { IMeetingRoomRequestStatus } from '../../types';
import ReactTooltip from 'react-tooltip';

const TimeList = (props: ITimeListProps) => {
  const { options, selected, onClick } = props;
  const tooltipPositions = ['right', 'bottom', 'left', 'right', 'top', 'left'];
  return (
    <div className={styles.container}>
      {options.map((option, index) => {
        const disableTime =
          option.status === IMeetingRoomRequestStatus.ACCEPTED;
        const isRequested =
          option.status === IMeetingRoomRequestStatus.REQUESTED;
        return (
          <div>
            <div
              className={classnames(styles.time, {
                [styles.activeTime]:
                  option.value.hours === (selected && selected.hours) &&
                  option.value.minutes === (selected && selected.minutes),
                [styles.disableTime]: disableTime,
              })}
              onClick={() =>
                !disableTime && onClick(option.value)
              }
              data-tip
              data-for={`meeting-time-${option.label}`}
            >
              {isRequested && <div className={styles.redDot}></div>}
              <Text
                text={option.label}
                textColor={
                  disableTime
                    ? ITextColors.NEUTRAL_MEDIUM_2
                    : ITextColors.MONOCHROME_CONTRAST
                }
                textSize={ITextSizes.XSMALL}
                textStyle={ITextStyles.BOLDED}
                textLineHeight="15px"
                readOnly={disableTime}
              />
            </div>
            {disableTime && (
              <ReactTooltip
                id={`meeting-time-${option.label}`}
                place={tooltipPositions[index]}
                effect="solid"
                backgroundColor="black"
              >
                Unavailable
              </ReactTooltip>
            )}
            {isRequested && (
              <ReactTooltip
                id={`meeting-time-${option.label}`}
                place={tooltipPositions[index]}
                effect="solid"
                backgroundColor="black"
              >
                User has a pending invite
              </ReactTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TimeList;
