import { useMemo, useEffect } from 'react';

import { usePusherState } from './messaging/pusher/pusher-context';
import { selectCurrentUser } from '@/models/account';
import { useSelector } from 'react-redux';

const useMessaging = (stageConfig, isHost) => {
  const pusherCtx = usePusherState();

  const { accountId } = useSelector(selectCurrentUser);

  const { pusher, attendeeChannel, hostChannel,attendeePresenceChannel, attendeeChannelName, attendeePresenceChannelName, hostChannelName, hostPresenceChannelName } = useMemo(() => {
    if (!stageConfig || !pusherCtx.pusher || !accountId) return {};
    const {
      messagingChannel: {
        authUrl,
        attendeeChannel: attendeeChannelName,
        hostChannel: hostChannelName
      },
    } = stageConfig;


    const attendeePresenceChannelName = attendeeChannelName && `${attendeeChannelName}-presence-${accountId}`;
    const hostPresenceChannelName = hostChannelName && `${hostChannelName}-presence-${accountId}`;

    let attendeeChannel;
    let hostChannel;
    let attendeePresenceChannel;

    if (attendeeChannelName) {
      attendeeChannel = pusherCtx.pusher.subscribe(attendeeChannelName);
      attendeePresenceChannel = pusherCtx.pusher.subscribe(attendeePresenceChannelName);
    }

    if (hostChannelName) {
      hostChannel = pusherCtx.pusher.subscribe(hostChannelName);
      pusherCtx.pusher.subscribe(hostPresenceChannelName);
    }

    return {
      pusher: pusherCtx.pusher,
      attendeeChannel: attendeeChannel,
      hostChannel: hostChannel,
      attendeePresenceChannel: attendeePresenceChannel,
      attendeeChannelName,
      attendeePresenceChannelName,
      hostChannelName,
      hostPresenceChannelName,
    };
  }, [stageConfig, accountId, pusherCtx]);

  const unSubscribeAttendeeChannel = () => {
    try {
      pusher.unsubscribe(attendeeChannelName);
      pusher.unsubscribe(attendeePresenceChannelName);
    } catch(e) {
      console.log('unSubscribeAttendeeChannel > ', e)
    }
    
  }

  const unSubscribeHostChannel = () => {
    try {
      pusher.unsubscribe(hostChannelName);
      pusher.unsubscribe(hostPresenceChannelName);
    } catch(e) {
      console.log('unSubscribeHostChannel > ', e)
    }
    
  }

  useEffect(() => {
    if (!attendeeChannel) return;

    return () => {
      pusher.unsubscribe(attendeeChannelName);
      pusher.unsubscribe(attendeePresenceChannelName);
    };
  }, [pusher, attendeeChannel, attendeeChannelName]);

  useEffect(() => {
    if (!hostChannel || !isHost) return;

    return () => {
      pusher.unsubscribe(hostChannelName);
      pusher.unsubscribe(hostPresenceChannelName);
    };
  }, [pusher, hostChannel, hostChannelName, isHost]);

  return [pusher, attendeeChannel, hostChannel, unSubscribeAttendeeChannel, unSubscribeHostChannel, attendeePresenceChannel];
};

export default useMessaging;
