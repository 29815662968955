import React, { createContext, useReducer, useContext } from 'react';
import { reducer, defaultState } from './user-channel-state';

const UserChannelStateContext = createContext({});
const UserChannelMutationContext = createContext({});

export const UserChannelContainerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const methods = {
    setUserChannel(userChannel) {
      dispatch({
        type: 'setUserChannel',
        payload: userChannel
      });
    }
  };

  return (
    <UserChannelStateContext.Provider value={state} >
      <UserChannelMutationContext.Provider value={methods}>
        {children}
      </UserChannelMutationContext.Provider>
    </UserChannelStateContext.Provider>
  );
};

export function useUserChannelState() {
  return useContext(UserChannelStateContext);
}

export function useUserChannelMutation() {
  return useContext(UserChannelMutationContext);
}

export function useUserChannelContext() {
  return [useUserChannelState(), useUserChannelMutation()];
}
