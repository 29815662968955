import { IAccountRoleTypeOptions } from '@/models/account/types';
import { ITicketTagTypes } from '@/types/tickets';

export enum ESocialMediaTypes {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  WEBSITE = 'WEBSITE',
}

interface ISocialMediaLinks {
  type: ESocialMediaTypes;
  url: string;
}

interface IInterests {
  label: string;
  value: string;
  isMatching: boolean;
}

export interface IProfileDetails {
  accountId: string;
  firstName: string;
  lastName: string;
  company: string;
  designation: string;
  picUrl: string;
  bio: string;
  socialLinks?: ISocialMediaLinks[];
  interests?: any[];
  ticketTypeId?: string;
  ticketTagType?: ITicketTagTypes;
  showTicketTagType?: boolean;
  hideScheduleAndChat?: boolean;
  eventRoleType?: IAccountRoleTypeOptions;
  meetingIntervalTimeObj?: any;
  isInvokedByProfilePreview?: boolean;
  isInterestsEnabledByOrganizer?: boolean;
  isPrivateMessagingEnabled?: boolean;
  isPrivateMeetingsEnabled?: boolean;
  showMeetingButton?: boolean;
  showChatButton?: boolean;
}

export interface IProfileDetailsProps extends IProfileDetails {
  onClickSchenduleMeeting: () => void;
  onClickInbox: () => void;
  onCloseModal: () => void;
}
