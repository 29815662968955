import { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import api from "@/api";
import { AttendeeChannelEvent } from "@/types/messaging";
import { useDispatch } from "react-redux";
import { makeSelectCurrentOverlayForChannel } from "@/models/event";
import { useMemoizedSelector } from "@/hooks/use-memoized-selector";

const useChannelImages = ({ channelConfig, attendeeChannel }) => {
  const currentOverlay = useMemoizedSelector(makeSelectCurrentOverlayForChannel, channelConfig && channelConfig.channelId);
  const location = useLocation();

  const currentOverlayRef = useRef(currentOverlay);
  useEffect(() => {
    if (!currentOverlayRef) {
      return;
    }
    currentOverlayRef.current = currentOverlay;
  }, [currentOverlay]);

  const dispatch = useDispatch();

  const getOverLayImages = () => {
    api.channel
      .getLiveChannelImages(channelConfig.channelId)
      .then(({ data: currentChannelImages }) => {
        let currentOverlayImage: any;
        currentChannelImages.forEach(image => {
          if (image.type === 'OVERLAY') {
            currentOverlayImage = image;
          }
        });

        if (currentOverlayImage) {
          dispatch({
            type: 'event/setCurrentOverlay',
            payload: {
              channelId: channelConfig.channelId,
              overlayImage: currentOverlayImage,
            },
          });
        } else {
          dispatch({
            type: 'event/clearCurrentOverlay',
            payload: {
              channelId: channelConfig.channelId,
            },
          });
        }
      });
  };

  useEffect(() => {
    if (!channelConfig || !channelConfig.channelId) {
      return;
    }
    dispatch({
      type: 'event/clearCurrentOverlay',
      payload: {
        channelId: channelConfig.channelId,
      },
    });
    getOverLayImages();
    const overlayInterval = setInterval(() => {
      if (!location.pathname.includes('stage')) {
        // except stage no need to check overlay image
        return;
      }
      getOverLayImages();
    }, 60000);
    return () => {
      clearInterval(overlayInterval);
    };
  }, [channelConfig, location.pathname]);

  useEffect(() => {
    if (!attendeeChannel) {
      return;
    }

    const updateCurrentOverlayImage = (data) => {
      const overlayImageUpdate = JSON.parse(data);

      if (currentOverlayRef && currentOverlayRef.current &&
        currentOverlayRef.current.channelImageId === overlayImageUpdate.channelImageId &&
        currentOverlayRef.current.isLive === overlayImageUpdate.isLive) {
        return;
      }

      if (overlayImageUpdate.isLive) {
        dispatch({
          type: 'event/setCurrentOverlay',
          payload: {
            channelId: channelConfig.channelId,
            overlayImage: overlayImageUpdate
          }
        });
      } else {
        dispatch({
          type: 'event/clearCurrentOverlay',
          payload: {
            channelId: channelConfig.channelId,
          }
        });
      }
    };
    attendeeChannel.bind(AttendeeChannelEvent.CHANNEL_IMAGE_UPDATE, updateCurrentOverlayImage);

    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.CHANNEL_IMAGE_UPDATE, updateCurrentOverlayImage);
    };
  }, [attendeeChannel]);

  return {
    currentOverlay
  };
};

export default useChannelImages;
