const defaultState = {
  eventAttendeeChannel: undefined,
  eventIndividualUserChannel: undefined,
  eventAttendeeTicketTypeChannel: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setEventAttendeeChannel': {
      return {
        ...state,
        eventAttendeeChannel: action.payload,
      };
    }
    case 'setEventIndividualUserChannel': {
      return {
        ...state,
        eventIndividualUserChannel: action.payload,
      };
    }
    case 'setEventAttendeeTicketTypeChannel': {
      return {
        ...state,
        eventAttendeeTicketTypeChannel: action.payload,
      };
    }
    default:
      throw new Error('pusher channel state > mutation type not defined');
  }
};

export { defaultState, reducer };
