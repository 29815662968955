import React, { useEffect, useMemo, useRef, useState } from 'react';
import { chunk, findIndex } from 'lodash';
import { useParams } from 'react-router';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'react-feather';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectEventById } from '@/models/event';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import IconButton, {
  IUIIcons,
  IIconButtonTypes,
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import { IButtonTypes } from '@/components/ui/buttons/Button/types';
import Loader from '@/components/ui/Loader';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

import styles from './styles.module.scss';
import { IBookMeetingRoomProps } from './types';
import DateList from './DateList';
import TimeList from './TimeList';
// api
import api from '@/api';
import { scheduledTimeOptionsList } from '@/pages/live-event/DiscussionListPage/ScheduleMeetingRoom/config';
import Button from '@/components/ui/buttons/Button';

const DateOptionsPerSlide = 3;
const TimeOptionsPerSlide = 6;

const BookMeetingRoomView = (props: IBookMeetingRoomProps) => {
  const {
    dateOptions,
    onClickBack,
    onSelect,
    onCloseModal,
    defaultSelected,
    meetingIntervalTimeObj,
    selectedAcoountId,
  } = props;
  const { eventId = '' } = useParams();
  const dispatch = useDispatch();
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const dateSliderRef = useRef<Slider>();
  const timeSliderRef = useRef<Slider>();
  const [displayTimeOptions, setDisplayTimeOptions] = useState([]);
  const [meetingFormLoading, setMeetingFormLoading] = useState(true) as any;

  useEffect(() => {
    setMeetingFormLoading(true);
  }, [selectedAcoountId]);

  const getTimeSlots = (selectedDate) => {
    api.segment
      .getAttendeeTimeSlots(
        eventId,
        selectedAcoountId,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        new Date(selectedDate)
      )
      .then(({ data }) => {
        setDisplayTimeOptions(scheduledTimeOptionsList(data));
        setMeetingFormLoading(false);
      })
      .catch(e => {
        setMeetingFormLoading(false);
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Slots fetching failed' },
        });
      });
  }
  const datesChunks = useMemo(() => chunk(dateOptions, DateOptionsPerSlide), [
    dateOptions.length,
  ]);

  const timeChunks = useMemo(
    () => chunk(displayTimeOptions, TimeOptionsPerSlide),
    [displayTimeOptions],
  );

  const findSliderIndexFromChunks = (
    allChunks: any[],
    lookingForCallback: (arg0: any) => boolean,
  ) =>
    findIndex(
      allChunks,
      oneChunk => findIndex(oneChunk, lookingForCallback) >= 0,
    );

  const handleOnSelecDate = dateOption => {
    setSelectedDate(dateOption);
    setSelectedTime(undefined);
    getTimeSlots(dateOption)
    setDisableButton(true);
    timeSliderRef.current && timeSliderRef.current.slickGoTo(0);
  };

  useEffect(() => {
    if (defaultSelected.date) {
      handleOnSelecDate(defaultSelected.date);
      const sliderIdx = findSliderIndexFromChunks(
        datesChunks,
        v => defaultSelected.date === v.value,
      );
      sliderIdx >= 0 &&
        dateSliderRef &&
        dateSliderRef.current.slickGoTo(sliderIdx);
    }
  }, []);

  useEffect(() => {
    if (selectedTime !== undefined) {
      setDisableButton(false);
    }
  }, [selectedTime]);

  const initialTimeSelectSliderPosition = useRef(false);

  useEffect(() => {
    if (initialTimeSelectSliderPosition.current) {
      return;
    }
    if (!defaultSelected.time) {
      initialTimeSelectSliderPosition.current = true;
      return;
    }
    if (timeChunks.length > 0) {
      setSelectedTime(defaultSelected.time);
      initialTimeSelectSliderPosition.current = true;
      const sliderIdx = findSliderIndexFromChunks(
        timeChunks,
        v =>
          defaultSelected.time.hours === v.value.hours &&
          defaultSelected.time.minutes === v.value.minutes,
      );
      sliderIdx >= 0 && timeSliderRef?.current?.slickGoTo(sliderIdx);
    }
  }, [timeChunks.length]);

  const handleOnSelectTime = timeOption => {
    setSelectedTime(timeOption);
    setDisableButton(false);
  };

  const handleOnSelectNext = () => {
    onSelect(selectedDate, selectedTime);
  };

  const NoTimeSlotsAvailableComp = (
    <div className={styles.noTimeSlot}>
      <Text
        text="No time slots available"
        textColor={ITextColors.MONOCHROME_CONTRAST}
        textSize={ITextSizes.MEDIUM}
        textStyle={ITextStyles.BOLDED}
        textLineHeight="22px"
        className={styles.text}
      />
    </div>
  );

  const noTimeSlotsAvailable = timeChunks.length === 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IconButton
          defaultIcon={IUIIcons.FEATHER_ARROW_LEFT}
          activeIcon={IUIIcons.FEATHER_ARROW_LEFT}
          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
          tooltip={TooltipTypes.none}
          type={IIconButtonTypes.ROUND_NEUTRAL_BASE_3}
          onClick={onClickBack}
          showDefault
        />
        <div>
          <Text
            text="Set up a meeting"
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={ITextSizes.XLARGE}
            textStyle={ITextStyles.BOLDED}
            textLineHeight="29px"
          />
          <div>
            <Text
              text="Choose a day and time for the meeting."
              textColor={ITextColors.NEUTRAL_DARK}
              textSize={ITextSizes.XSMALL}
              textStyle={ITextStyles.REGULAR}
            />
          </div>
        </div>
      </div>
      <div className={styles.daySelectorWrapper}>
        <div
          className={styles.prevArrow}
          onClick={dateSliderRef.current && dateSliderRef.current.slickPrev}
        >
          <ChevronLeft
            size={16}
            color={getCssVar(IContentColors.NEUTRAL_CONTRAST_2)}
          />
        </div>
        <div className={styles.slider}>
          <Slider
            ref={dateSliderRef}
            swipe={false}
            touchMove={false}
            infinite={false}
            arrows={false}
            speed={500}
            adaptiveHeight={false}
          >
            {datesChunks.map(oneChunk => (
              <DateList
                options={oneChunk}
                selected={selectedDate}
                onClick={handleOnSelecDate}
              />
            ))}
          </Slider>
        </div>
        <div
          className={styles.nextArrow}
          onClick={dateSliderRef.current && dateSliderRef.current.slickNext}
        >
          <ChevronRight
            size={16}
            color={getCssVar(IContentColors.NEUTRAL_CONTRAST_2)}
          />
        </div>
      </div>
      {noTimeSlotsAvailable && NoTimeSlotsAvailableComp}
      <div
        className={classnames(styles.timeSelectorWrapper, {
          [styles.hideTimeSlider]: noTimeSlotsAvailable,
        })}
      >
        <div
          className={styles.prevArrow}
          onClick={timeSliderRef.current && timeSliderRef.current.slickPrev}
        >
          <ChevronLeft
            size={16}
            color={getCssVar(IContentColors.NEUTRAL_CONTRAST_2)}
          />
        </div>
        {meetingFormLoading && (
          <div>
            <Loader className={styles.uploadingIcon} size={16} />
          </div>
        )}
        <div className={styles.slider}>
          <Slider
            ref={timeSliderRef}
            swipe={false}
            touchMove={false}
            infinite={false}
            arrows={false}
            speed={500}
            adaptiveHeight={false}
          >
            {timeChunks.map(oneChunk => (
              <TimeList
                options={oneChunk}
                selected={selectedTime}
                onClick={handleOnSelectTime}
              />
            ))}
          </Slider>
        </div>
        <div
          className={styles.nextArrow}
          onClick={timeSliderRef.current && timeSliderRef.current.slickNext}
        >
          <ChevronRight
            size={16}
            color={getCssVar(IContentColors.NEUTRAL_CONTRAST_2)}
          />
        </div>
      </div>
      <div className={styles.nextButton}>
        <Button
          type="button"
          onClick={handleOnSelectNext
          }
          styleClass={IButtonTypes.PRIMARY}
          disabled={disableButton}
          label='next'
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BookMeetingRoomView;
