import { EVolumeLevels } from '@/components/PublishedStreamDisplay/SingleStreamPlayer/StreamLabel/types';
import { useEffect, useState } from 'react';

const useAudioVolume = ({ audioTrack, isLocalStream, isLocalAudioEnabled }) => {
  // Using stringifed object here so that the component is not re rendered
  // whenever the value is updated
  const [stringifiedAudioConfig, setStringifiedAudioConfig] = useState(
    JSON.stringify({
      isAudioEnabled: false,
      currentVolumeLevel: EVolumeLevels.NONE,
    }),
  );

  const setAudioConfig = ({ isAudioEnabled, currentVolumeLevel }) => {
    setStringifiedAudioConfig(
      JSON.stringify({
        isAudioEnabled,
        currentVolumeLevel,
      }),
    );
  };

  useEffect(() => {
    if (isLocalStream && !audioTrack) {
      // This is for local screenshared stream which does not have an audio track
      setAudioConfig({ isAudioEnabled: false, currentVolumeLevel: EVolumeLevels.NONE});
      return;
    }
    if (isLocalStream && !isLocalAudioEnabled) {
      setAudioConfig({ isAudioEnabled: false, currentVolumeLevel: EVolumeLevels.NONE });
      return;
    }
    const isRemoteAudioEnabled = Boolean(audioTrack);
    if (!isLocalStream && !isRemoteAudioEnabled) {
      setAudioConfig({ isAudioEnabled: false, currentVolumeLevel: EVolumeLevels.NONE});
      return;
    }
    const volumeInterval = setInterval(() => {
      let level = EVolumeLevels.NONE; // level = we are getting frequency if user is speaking 
      const audioVolLevel = audioTrack.getVolumeLevel();
      // Changed after upgrading to Agora 4.14.0, no documentation changes
      if (audioVolLevel > 0.5 && audioVolLevel < 0.75) {
        level = EVolumeLevels.LOW;
      }
      if (audioVolLevel >= 0.75) {
        level = EVolumeLevels.HIGH;
      }

      setAudioConfig({ isAudioEnabled: true, currentVolumeLevel: level });
    }, 250);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(volumeInterval);
    };
  }, [audioTrack, isLocalStream, isLocalAudioEnabled]);

  const { isAudioEnabled, currentVolumeLevel } = JSON.parse(
    stringifiedAudioConfig,
  );

  return {
    isAudioEnabled,
    currentVolumeLevel,
    hasActiveVolume: currentVolumeLevel !== EVolumeLevels.NONE,
  };
};

export default useAudioVolume;
