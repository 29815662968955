import { useEffect , useCallback, useRef} from "react";
import api from "@/api";
import { AttendeeChannelEvent } from "@/types/messaging";
import { useDispatch } from "react-redux";
import { makeSelectCurrentLiveStreamForChannel } from "@/models/event";
import { useMemoizedSelector } from "@/hooks/use-memoized-selector";

const useChannelLiveStream = ({ channelConfig = {}, attendeeChannel }) => {
  const { channelId } = channelConfig;
  const dispatch = useDispatch();
  const currentLiveStream = useMemoizedSelector(makeSelectCurrentLiveStreamForChannel, channelId);

  const currentLiveStreamRef = useRef();
  useEffect(() => {
    currentLiveStreamRef.current = currentLiveStream;
  },[currentLiveStream]);
  
  useEffect(() => {
    if (!channelId) {
      return;
    }

    api.channel.getCurrentChannelLiveStream(channelId).then(({ data }) => {
      dispatch({
        type: 'event/setCurrentLiveStream',
        payload: {
          channelId,
          liveStream: data
        }
      });
    });

    // TODO: live stream interval should be a ref
    const liveStreamInterval = setInterval(() => {
      if (currentLiveStream) {
        return;
      }

      api.channel.getCurrentChannelLiveStream(channelId).then(({ data }) => {
        dispatch({
          type: 'event/setCurrentLiveStream',
          payload: {
            channelId,
            liveStream: data
          }
        });
      });
    }, 60000);

    return (() => {
      dispatch({
        type: 'event/setCurrentLiveStream',
        payload: {
          channelId,
          videoInfo: null
        }
      });

      clearInterval(liveStreamInterval);
    });
  }, [channelId]);

  const startLiveStreamListener = (data) => {
      const liveStream = JSON.parse(data);
      if(currentLiveStreamRef.current && currentLiveStreamRef.current.liveStreamUrl === liveStream.liveStreamUrl){
        return;
      }
      dispatch({
        type: 'event/setCurrentLiveStream',
        payload: {
          channelId,
          liveStream
        }
      });
  };

  const stopLiveStreamListener = (data) => {
    const liveStream = JSON.parse(data);
    if(!currentLiveStreamRef.current || currentLiveStreamRef.current.liveStreamUrl !== liveStream.liveStreamUrl){
        return;
    }
    dispatch({
      type: 'event/clearCurrentLiveStream',
      payload: {
        channelId,
        liveStream
      }
    });
  };

  useEffect(() => {
    if (!attendeeChannel) {
      return;
    }

    attendeeChannel.bind(AttendeeChannelEvent.LIVE_STREAM_START, startLiveStreamListener);
    attendeeChannel.bind(AttendeeChannelEvent.LIVE_STREAM_STOP, stopLiveStreamListener);
    
    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.LIVE_STREAM_START, startLiveStreamListener);
      attendeeChannel.unbind(AttendeeChannelEvent.LIVE_STREAM_STOP, stopLiveStreamListener);
    };
  }, [attendeeChannel]);

  const startLiveStream = (isSwitchTo, fromLiveStreamId, toLiveStreamId) => {
    if(isSwitchTo){
      api.channel.switchChannelLiveStream(channelId, fromLiveStreamId, toLiveStreamId);
      return;
    }
    api.channel.startChannelLiveStream(channelId, toLiveStreamId);
  };

  const stopLiveStream = (liveStreamId) => {
    api.channel.stopChannelLiveStream(channelId, liveStreamId);
  }

  return {
    currentLiveStream,
    startLiveStream,
    stopLiveStream,
  };
};

export default useChannelLiveStream;
