const defaultState = {
  userChannel: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setUserChannel': {
      return {
        userChannel: action.payload
      };
    }
    default:
      throw new Error('pusher channel state > mutation type not defined');
  }
};

export { defaultState, reducer };
