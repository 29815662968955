const validations = {
  // Validation Utils
  composeValidators(...validators) {
    return (value, allValues) =>
      validators.reduce(
        (error, validator) => error || validator(value, allValues),
        undefined,
      );
  },

  noValidation() {
    return undefined;
  },

  // Common Validations
  required(value) {
    if(value===undefined || value === null || value==="" || value.length === 0){
      return 'This field is required';
    }
    if (typeof value === 'string' && value.trim().length === 0) {
       return 'This field is required';
    }
    return undefined;
  },

  // Text Validations
  email(value) {
    const emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/g;
    return emailRegex.test(value) ? undefined : 'Please provide a valid email';
  },

  minLength(min) {
    return value => {
      const length = (value && value.trim().length) || 0;
      return length >= min
        ? undefined
        : `Should be longer than ${min} characters`;
    };
  },

  maxLength(max) {
    return value => {
      const length = (value && value.trim().length) || 0;
      // if(length === 0) { return 'This field is required' }
      return length <= max
        ? undefined
        : `Should be shorter than ${max} characters`;
    };
  },

  // Number Validations
  number(value) {
    if (!value) return;
    return isNaN(value) ? 'Must be a number' : undefined;
  },

  minValue(min) {
    return value =>
      isNaN(value) || value >= min
        ? undefined
        : `Should be greater than ${min}`;
  },

  maxValue(max) {
    return value =>
      isNaN(value) || value <= max
        ? undefined
        : `Should be less than ${max}`;
  },

  validFirstName(value) {
    // Check first name should not be default values
    if (value === 'Event') {
      return 'Set a valid first name';
    }
    return undefined;
  },

  validLastName(value) {
    // Check last name should not be default values
    if (value === 'Attendee') {
      return 'Set a valid last name';
    }
    return undefined;
  },

  passwordRules(password) {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).*$/;
    if (password.match(decimal)) {
      return undefined;
    }
    else {
      return 'Invalid Password';
    }
  },
  domainRules(domain) {
    // Check if the inputted domain is a valid domain
    // NOTE - it does not allow for sub-domains!
    const domainRegex = /^([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}$/;
    if (domain.match(domainRegex)) {
      return undefined;
    }
    return 'Must be a valid domain';
  },

  // Form Level Validations - when the check is based on more than one field.
  oneOfRequired(fieldObjects) {
    return (value, allValues) => {
      if (!allValues) return undefined;

      for (const { name: fieldName } of fieldObjects) {
        const fieldValue = allValues[fieldName];
        if (!!fieldValue) {
          return undefined;
        }
      }
      return `One of ${fieldObjects.map(f => f.label).join(", ")} must be provided`;
    }
  },

  conditionalRequired(conditionFn) {
    return (value, allValues) => {
      if (!allValues) return undefined;

      if (conditionFn(value, allValues)) {
        return value ? undefined : 'This field is required';
      } else {
        return undefined;
      }
    }
  },

  confirmPasswordsMatch(input, allInputs) {
    return input === allInputs.password ? undefined : 'Passwords do not match';
  },
};
export default validations;
