import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import TimerFill from '@/components/ui/new-icons/TimerFill';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import Button from '@/components/ui/buttons/Button';
import { IButtonTypes } from '@/components/ui/buttons/Button/types';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import IconButton, {
  IIconButtonTypes,
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import { IContentColors, IUIIcons } from '@/types';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import { getCssVar } from '@/utils/cssVars';
import ProfilePicture from '@/components/ui/ProfilePicture';
import { getFullName } from '@/utils/helpers';
import DoubleTickIcon from '@/components/ui/new-icons/DoubleTick';
import api from '@/api';

import styles from './styles.module.scss';
import { IConfirmMeetingRoomProps } from './types';
import { dateDisplayFormat } from '../config';

import MapPinRangeFill from '@/components/ui/new-icons/MapPinRangeFill';
import { IUserPrivacyErrors } from '@/types/userPrivacySetting';
const ConfirmMeetingRoomView = (props: IConfirmMeetingRoomProps) => {
  const {
    accountId,
    firstName,
    lastName,
    designation,
    company,
    picUrl,
    selectedDateTime: { date: selectedDate, time: selectedTime },
    selectedMeetingTypeObject: {
      type: selectedType = undefined,
      location: selectedLocation = undefined,
    },
    onClickBack,
    onCloseModal,
    meetingIntervalTimeObj
  } = props;
  const { eventId = '' } = useParams();
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const fullName = getFullName(firstName, lastName);

  const selectedDateTime = useMemo(() => {
    const d = selectedDate;
    d.setHours(selectedTime.hours);
    d.setMinutes(selectedTime.minutes);
    return d;
  }, [selectedDate, selectedTime]);

  const handleConfirmMeeting = () => {
    setIsConfirming(true);
    const meetingRoomObj = {
      requestedAccountId: accountId,
      eventId,
      startTime: selectedDateTime,
      isScheduled: true,
      durationMins:
        (meetingIntervalTimeObj && meetingIntervalTimeObj.durationMins) || 15,
      meetingType: selectedType,
      meetingLocation: selectedLocation,
    };
    api.meetingRoom
      .meetingRoomRequest(meetingRoomObj)
      .then(() => {
        setIsConfirming(false);
        setIsConfirmed(true);
      })
      .catch((err) => {
        setIsConfirming(false);
        let description = 'There was an issue scheduling the meeting room.';
        if (err?.response?.data?.message === IUserPrivacyErrors.PRIVATE_MEETINGS_BLOCKED) {
          description = 'Meeting cannot be requested as the user has turned off private meetings';
        }
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: description
          },
        });
      });
  };

  const [dateText, timeText] = dateDisplayFormat(selectedDateTime, {
    sameDay: '[Today,] Do MMM[at]hh:mm A',
    nextDay: '[Tomorrow,] Do MMM[at]hh:mm A',
    nextWeek: 'Do MMM[at]hh:mm A',
    lastDay: 'Do MMM[at]hh:mm A',
    lastWeek: 'Do MMM[at]hh:mm A',
    sameElse: 'Do MMM[at]hh:mm A',
  }).split('at');

  const BeforeConfirmHeader = (
    <div className={styles.beforeConfirmHeader}>
      <div className={styles.datetime}>
        <IconButton
          defaultIcon={IUIIcons.FEATHER_ARROW_LEFT}
          activeIcon={IUIIcons.FEATHER_ARROW_LEFT}
          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
          tooltip={TooltipTypes.none}
          type={IIconButtonTypes.ROUND_NEUTRAL_BASE_3}
          onClick={onClickBack}
          showDefault
        />
        <div>
          <Text
            text="Set up a meeting"
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={ITextSizes.XLARGE}
            textStyle={ITextStyles.BOLDED}
            textLineHeight="29px"
          />
          <div>
            <Text
              text="Choose a day and time for the meeting."
              textColor={ITextColors.NEUTRAL_DARK}
              textSize={ITextSizes.XSMALL}
              textStyle={ITextStyles.REGULAR}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const AfterConfirmHeader = (
    <div className={styles.afterConfirmHeader}>
      <div className={styles.tickIconWrapper}>
        <div className={styles.icon}>
          <DoubleTickIcon size={12} color={IContentColors.WHITE} />
        </div>
        <div className={styles.isLiveOuter} />
        <div className={styles.isLiveInner} />
      </div>
      <Text
        text="Meeting Slot Requested!"
        textSize={ITextSizes.LARGE}
        textColor={ITextColors.MONOCHROME_CONTRAST}
        textStyle={ITextStyles.BOLDED}
        textLineHeight="24px"
      />
    </div>
  );

  return (
    <div className={styles.container}>
      {isConfirmed ? AfterConfirmHeader : BeforeConfirmHeader}
      <div className={styles.meetingDetails}>
        <Text
          text="Meeting with"
          textColor={ITextColors.NEUTRAL_DARK}
          textSize={ITextSizes.XSMALL}
          textStyle={ITextStyles.REGULAR}
        />
        <div className={styles.about}>
          <div className={styles.aboutPerson}>
            <div className={styles.aboutPersonHeader}>
              <div className={styles.profilePicWrapper}>
                <ProfilePicture
                  name={fullName}
                  imageUrl={picUrl}
                  styleClass="smartNetworkingCardProfilePic"
                  disableTooltip
                  hexColor={getCssVar(IContentColors.NEUTRAL_BASE_3)}
                  isLive={false}
                />
              </div>
              <div className={styles.detailsWrapper}>
                <Text
                  text={fullName}
                  textSize={ITextSizes.MEDIUM}
                  textColor={ITextColors.MONOCHROME_CONTRAST}
                  textStyle={ITextStyles.BOLDED}
                  textLineHeight="19px"
                  className={styles.singleLineText}
                />
                {designation && (
                  <Text
                    text={designation}
                    textSize={ITextSizes.SMALL}
                    textColor={ITextColors.NEUTRAL_DARK}
                    textStyle={ITextStyles.BOLDED}
                    textLineHeight="16px"
                    className={styles.singleLineText}
                  />
                )}
                {company && (
                  <Text
                    text={`at ${company}`}
                    textSize={ITextSizes.SMALL}
                    textColor={ITextColors.NEUTRAL_DARK}
                    textLineHeight="16px"
                    className={styles.singleLineText}
                  />
                )}
                {selectedLocation && (
                  <div className={styles.selectedLocationContainer}>
                    <div className={styles.iconContainer}>
                      <MapPinRangeFill color={IContentColors.WHITE} />
                    </div>
                    <Text
                      text={selectedLocation}
                      textSize={ITextSizes.SMALL}
                      textColor={ITextColors.NEUTRAL_DARK}
                      textLineHeight="16px"
                      className={styles.singleLineText}
                    />
                  </div>
                )}
              </div>
            </div>
            <Text
          text="Time"
          textColor={ITextColors.NEUTRAL_DARK}
          textSize={ITextSizes.XSMALL}
          textStyle={ITextStyles.REGULAR}
        />
            <div className={styles.timeContainer}>
              <div className={styles.aboutMeeting}>
                <Text
                  text={`${dateText} • ${timeText}`}
                  textSize={ITextSizes.XSMALL}
                  textColor={ITextColors.MONOCHROME_CONTRAST}
                  textStyle={ITextStyles.SEMI_BOLDED}
                  textLineHeight="15px"
                />
              </div>
              <div className={styles.aboutMeetingIntervel}>
                {!isConfirmed && (
                  <TimerFill size={14} color={IContentColors.MONOCHROME_CONTRAST} />
                )}
                <Text
                  text={isConfirmed ? timeText : `${(meetingIntervalTimeObj && meetingIntervalTimeObj.durationMins) || 15} min`}
                  textSize={ITextSizes.XSMALL}
                  textColor={ITextColors.MONOCHROME_CONTRAST}
                  textLineHeight="15px"
                />
              </div>
            </div>
          </div>
        </div>
        {!isConfirmed && (
          <div className={styles.confirmMeeting}>
            <IconTextButton
              activeIcon={IUIIcons.LOADER}
              defaultIcon={IUIIcons.FEATHER_CALENDAR}
              defaultLabel="Confirm Meeting"
              activeLabel="Confirming"
              onClick={handleConfirmMeeting}
              showDefault={!isConfirming}
              buttonType={IIconTextButtonTypes.PRIMARY}
              iconSize={16}
            />
          </div>
        )}
        {isConfirmed && (
          <div className={styles.confirmMeeting}>
            <Button
                type="button"
                onClick={() => {
                  onCloseModal();
                }
                }
                styleClass={IButtonTypes.PRIMARY}
                label="okay"
              >
                Okay
              </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmMeetingRoomView;
