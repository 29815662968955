import { useEffect, useCallback } from 'react';
import api from '@/api';
import { AttendeeChannelEvent, HostChannelEvent } from '@/types/messaging';
import { makeSelectUserAccountId } from '@/models/account';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { IStageParent } from '@/components/Stage/types';
import { useDispatch } from 'react-redux';
import { useVideoState } from './video-context';
import { useLatest } from 'react-use';

const useUserChannelChanges = ({
  channelConfig,
  localClient,
  attendeeChannel,
  hostChannel,

  stopScreenShare,

  raisedHandStatus,
  completeMyRaisedHandRequest,

  audienceGalleryRequestStatus,
  completeMyAudienceGalleryRequest,

  contributorViewRequestStatus,
  completeMyContributorViewRequest,

  setBroadcastPermissions,
  videoHost,
  eventId,
  stageId,
}) => {
  const dispatch = useDispatch();
  const { screenShareStreamId } = useVideoState();
  // TODO: kickoff only when both people are in the same channel
  const handleUserKickout = uid => {
    if (!localClient) {
      return;
    }

    const channelName = (() => {
      if (
        localClient.greenRoomMainClient &&
        localClient.greenRoomMainClient._params.channel
      ) {
        return localClient.greenRoomMainClient._params.channel;
      }

      if (localClient.mainClient && localClient.mainClient._params.channel) {
        return localClient.mainClient._params.channel;
      }
    })();

    api.channel.kickoffUser({
      channelId: channelConfig.channelId,
      channelName: channelName,
      uid,
    });
  };

  const moveUserToStage = uid => {
    if (!localClient) {
      return;
    }

    const channelName = (() => {
      if (
        localClient.greenRoomMainClient &&
        localClient.greenRoomMainClient._params.channel
      ) {
        return localClient.greenRoomMainClient._params.channel;
      }
    })();

    api.channel.moveUserToStage({
      channelId: channelConfig.channelId,
      channelName: channelName,
      uid,
    });
  };

  const moveUserToGreenRoom = uid => {
    if (!localClient) {
      return;
    }

    const channelName = (() => {
      if (localClient.mainClient && localClient.mainClient._params.channel) {
        return localClient.mainClient._params.channel;
      }
    })();

    api.channel.moveUserToGreenRoom({
      channelId: channelConfig.channelId,
      channelName: channelName,
      uid,
    });
  };

  const leave = useCallback(async () => {
    if (videoHost && parent === IStageParent.LIVE_BACKSTAGE && !localClient.inGreenRoom()) {
      await localClient.stageToGreenRoom();
      location.reload();
      return;
    }

    await localClient.leaveScreenAndUnpublishMain();
    if (raisedHandStatus) {
      await completeMyRaisedHandRequest();
    }

    if (audienceGalleryRequestStatus) {
      await completeMyAudienceGalleryRequest();
    }

    if (contributorViewRequestStatus) {
      await completeMyContributorViewRequest();
    }

    setBroadcastPermissions(false);

    location.reload();
  }, [videoHost]);

  // TODO: loading states while the transition is happening
  const moveToStage = async () => {
    console.error('moveToStage');
    await localClient.greenRoomToStage();
  };

  // TODO: loading states while the transition is happening
  const moveToGreenRoom = async () => {
    console.error('moveToGreenRoom');
    await localClient.stageToGreenRoom();
  };

  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);

  const setEventBroadcastingStatus = isBroadcasting => {
    if (isBroadcasting !== undefined) {
      dispatch({
        type: 'event/setEventIsBroadcasting',
        payload: {
          eventId,
          isBroadcasting: isBroadcasting,
          stageId,
        },
      });
    }
  };

  const latestScreenShareStreamId = useLatest(screenShareStreamId);

  useEffect(() => {
    if (!attendeeChannel) {
      return;
    }

    const kickoffListener = data => {
      const { accountId, channelId, channelName, uid, isBroadcasting } = JSON.parse(data);
      if (accountId !== currentUserAccountId) {
        return;
      }
      if (channelId !== channelConfig.channelId) {
        return;
      }
      if(uid === latestScreenShareStreamId.current) {
        console.error('debugScreen > Screen share was stopped', accountId)
        // If uid is screen uid then should stop screen share and not kick people out
        stopScreenShare();
        return;
      }
      leave();
      dispatch({
        type: 'global/setStageVolume',
        payload: false,
      });
      setEventBroadcastingStatus(isBroadcasting);
    };
    attendeeChannel.bind(AttendeeChannelEvent.KICKOFF, kickoffListener);

    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.KICKOFF, kickoffListener);
    };
  }, [attendeeChannel]);

  useEffect(() => {
    console.log('channel change', hostChannel);
    if (!hostChannel) {
      return;
    }

    const moveToStageListener = async data => {
      const { accountId, channelId, channelName, uid, isBroadcasting } = JSON.parse(data);
      if (!videoHost) {
        // If the user in on stage viewing as attendee, then videoHost is false and
        // we should not change anything here
        return;
      }
      if (accountId !== currentUserAccountId) {
        return;
      }
      if (channelId !== channelConfig.channelId) {
        return;
      }
      await moveToStage();
      dispatch({
        type: 'global/setStageVolume',
        payload: true,
      });
      setEventBroadcastingStatus(isBroadcasting);
    };
    hostChannel.bind(HostChannelEvent.MOVE_STAGE, moveToStageListener);

    const moveToGreenRoomListener = async data => {
      const { accountId, channelId, channelName, uid, isBroadcasting } = JSON.parse(data);
      if (!videoHost) {
        // If the user in on stage viewing as attendee, then videoHost is false and
        // we should not change anything here
        return;
      }
      if (accountId !== currentUserAccountId) {
        return;
      }
      if (channelId !== channelConfig.channelId) {
        return;
      }
      await moveToGreenRoom();
      dispatch({
        type: 'global/setStageVolume',
        payload: false,
      });
      setEventBroadcastingStatus(isBroadcasting);
    };
    hostChannel.bind(HostChannelEvent.MOVE_GREEN_ROOM, moveToGreenRoomListener);

    const breakoutRoomListener = data => {
      const { stageId } = JSON.parse(data);
      dispatch({
        type: 'event/getBreakoutRoomsConfig',
        payload: {
          eventId,
          stageId,
        },
      });
    };

    hostChannel.bind(HostChannelEvent.STAGE_BREAKOUT, breakoutRoomListener);
    hostChannel.bind(HostChannelEvent.STAGE_BRINGBACK, breakoutRoomListener);

    return () => {
      hostChannel.unbind(HostChannelEvent.MOVE_STAGE, moveToStageListener);
      hostChannel.unbind(
        HostChannelEvent.MOVE_GREEN_ROOM,
        moveToGreenRoomListener,
      );
      hostChannel.unbind(HostChannelEvent.STAGE_BREAKOUT, breakoutRoomListener);
      hostChannel.unbind(HostChannelEvent.STAGE_BRINGBACK, breakoutRoomListener);
    };
  }, [hostChannel, videoHost]);

  return {
    handleUserKickout,
    moveUserToStage,
    moveUserToGreenRoom,
  };
};

export default useUserChannelChanges;
