import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectUserAccountId } from '@/models/account';
import api from '@/api';
import {
  makeSelectCurrentPresentationForChannel,
  makeSelectIsOrganizer,
} from '@/models/event';

import {
  TSetCurrentPresentationBackend,
  TCurrentPresentationUI,
  IUseChannelPresentationProps,
  IUseChannelPresentationResponse,
  IPresentationListObject,
  TUpdateCurrentPresentationBackend,
  IPusherEvents,
} from './useChannelPresentation.types';

const useChannelPresentation = ({
  channelId,
  attendeeChannel,
}: IUseChannelPresentationProps): IUseChannelPresentationResponse => {
  const { eventId } = useParams<any>();
  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const dispatch = useDispatch();
  const [presentationList, setPresentationList] = useState<
    IPresentationListObject[]
  >([]);
  const lastActionTimeRef = useRef<number>(0);

  const currentPresentation: TCurrentPresentationUI = useMemoizedSelector(
    makeSelectCurrentPresentationForChannel,
    channelId,
  );
  const setCurrentPresentation = (presentation: TCurrentPresentationUI) => {
    dispatch({
      type: 'event/setCurrentPresentation',
      payload: {
        channelId,
        presentation,
      },
    });
  };

  const setCurrentPresentationWithApiResponse = (
    apiResponse: TSetCurrentPresentationBackend,
  ) => {
    if (!apiResponse) {
      setCurrentPresentation(null);
      return;
    }
    const {
      channelPresentationId: presentationId,
      presentationName,
      presentationUrl,
      currentPresentationPage,
      presentedBy,
      totalPages,
    } = apiResponse;

    const currentPresentationObj: TCurrentPresentationUI = {
      presentationId,
      presentationName,
      presentationUrl,
      currentPresentationPage,
      presentationTotalPages: totalPages || 1,
      hasPresentationControl: currentUserAccountId === presentedBy,
      presentedBy,
    };
    setCurrentPresentation(currentPresentationObj);
  };

  const updateCurrentPresentation = updateObj => {
    dispatch({
      type: 'event/updateCurrentPresentation',
      payload: {
        channelId,
        updateObj,
      },
    });
  };

  const updateCurrentPresentationWithApiResponse = (
    apiResponse: TUpdateCurrentPresentationBackend,
  ) => {
    if (!apiResponse) {
      return;
    }

    const {
      currentPresentationPage,
      channelPresentationId: presentationId,
    } = apiResponse;

    const updatePresentationObject = {
      presentationId,
      currentPresentationPage,
    };
    updateCurrentPresentation(updatePresentationObject);
  };

  const updatePresentationList = () => {
    api.channel.viewPresentationList(channelId).then(({ data }) => {
      if (data) {
        setPresentationList(data);
      }
    });
  };

  const updateCurrentPresentationState = () => {
    api.channel.viewCurrentPresentation(channelId).then(({ data }) => {
      setCurrentPresentationWithApiResponse(data);
    });
  };

  const start = (presentationId: string) => {
    const currentPresentationTemp = currentPresentation
      ? { ...currentPresentation }
      : null;
    setCurrentPresentationWithApiResponse(null);
    api.channel.startPresentation(channelId, presentationId).catch(() => {
      if (currentPresentationTemp) {
        // If there was a presentation running  when user changed to other presentation
        // and the other could not be started, we rever this change
        setCurrentPresentation(currentPresentationTemp);
      }
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description:
            'There was a problem starting the presentation, please try again',
        },
      });
    });
  };

  const add = (
    name: string,
    url: string,
    totalPages: number,
    isTemp: boolean = false,
    presentImmediately: boolean = false,
  ) =>
    api.channel
      .addPresentation({
        channelId,
        name,
        url,
        presentImmediately,
        isTemp,
        totalPages,
      })
      .then(() => undefined)
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description:
              'There was a problem adding the presentation, please try again',
          },
        });
      });

  const goToPage = (toPage: number) => {
    api.channel
      .changePresentationPage(
        channelId,
        currentPresentation?.presentationId,
        toPage,
      )
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description:
              'There was a problem changing the page, please try again',
          },
        });
      });
  };

  const stop = () => {
    api.channel.stopPresentations(channelId).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description:
            'There was a problem stopping the presentation, please try again',
        },
      });
    });
  };

  const setLoadingPresentation = () => {
    setCurrentPresentation({
      presentationId: 'loading-presentation',
      presentationName: 'Loading Presentation',
      presentationTotalPages: 1,
      currentPresentationPage: 1,
      hasPresentationControl: false,
      isLoading: true,
      presentedBy: currentUserAccountId,
    });
  };

  const refreshCurrentPresentationState = () => {
    updateCurrentPresentationState();
  };

  useEffect(() => {
    if (!channelId) {
      return;
    }
    updateCurrentPresentationState();
  }, [channelId]);

  useEffect(() => {
    if (!channelId || !isOrganizer) {
      return;
    }
    updatePresentationList();
  }, [channelId, isOrganizer]);

  useEffect(() => {
    if (!attendeeChannel || !currentUserAccountId) {
      return;
    }

    const setPresentationHandler = data => {
      const { presentationData, actionTimeMs } = JSON.parse(data);
      // data also provied actionByAccountId key.
      // If you want to see who performed this action

      if (actionTimeMs < lastActionTimeRef.current) {
        // To make sure we always apply the latest action by pusher
        return;
      }
      lastActionTimeRef.current = actionTimeMs;

      if (!presentationData) {
        setCurrentPresentationWithApiResponse(null);
        return;
      }
      setCurrentPresentationWithApiResponse(presentationData);
    };

    const updatePresentationHandler = data => {
      const { presentationData, actionTimeMs } = JSON.parse(data);
      // data also provied actionByAccountId key.
      // If you want to see who performed this action

      if (actionTimeMs < lastActionTimeRef.current) {
        // To make sure we always apply the latest action by pusher
        return;
      }
      lastActionTimeRef.current = actionTimeMs;

      updateCurrentPresentationWithApiResponse(presentationData);
    };

    attendeeChannel.bind(
      IPusherEvents.SET_CHANNEL_PRESENTATION,
      setPresentationHandler,
    );
    attendeeChannel.bind(
      IPusherEvents.UPDATE_CHANNEL_PRESENTATION,
      updatePresentationHandler,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      attendeeChannel.unbind(
        IPusherEvents.SET_CHANNEL_PRESENTATION,
        setPresentationHandler,
      );
      attendeeChannel.unbind(
        IPusherEvents.UPDATE_CHANNEL_PRESENTATION,
        updatePresentationHandler,
      );
    };
  }, [attendeeChannel, currentUserAccountId, currentPresentation]);

  return {
    presentationList,
    currentPresentation,
    addPresentation: add,
    refreshPresentationList: updatePresentationList,
    startPresentation: start,
    goToPresentationPage: goToPage,
    stopPresentation: stop,
    setLoadingPresentation,
    refreshCurrentPresentationState,
  };
};

export { IUseChannelPresentationResponse };

export default useChannelPresentation;
