// Logs added using this method will only be visible on the desired
// build environments or eventId or custom config.

const _environment = process.env.ENV as EENVIRONMENTS;

const myConsole = window.console;

// If you want to add logger for another environment, simply add that environment
// to the ENVIRONMENTS enum and access it using its value;
enum EENVIRONMENTS {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

// If you want add more loggers to this custom logger, simply add the method name here
// Make sure that method name exists on the console object.
enum EConsoleAvailableMethods {
  LOG = 'log',
  ERROR = 'error',
  DEBUG = 'debug',
  WARN = 'warn',
}

type genericConsoleMethod = (...data: any[]) => void;

type TConsoleMethodsValueFields = {
  [key in EConsoleAvailableMethods]: genericConsoleMethod;
};

interface ICustomLogger extends TConsoleMethodsValueFields {}

const notAvailableMethod: genericConsoleMethod = () => undefined;

const emptyConsoleMethods = Object.values(EConsoleAvailableMethods).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: notAvailableMethod,
  }),
  {},
) as ICustomLogger;

const bindedConsoleMethods = Object.values(EConsoleAvailableMethods).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: myConsole[curr].bind(console),
  }),
  {},
) as ICustomLogger;

const checkEventMatch = (eventId: string) =>
  window.location &&
  window.location.pathname &&
  window.location.pathname.includes(`/event/${eventId}`);

interface ICustomCondition {
  eventId?: string;
  environments?: EENVIRONMENTS | EENVIRONMENTS[];
}

const customConfigValidate = ({
  eventId,
  environments: env,
}: ICustomCondition) => {
  let validate = true;
  if (eventId && !checkEventMatch(eventId)) {
    validate = false;
  }
  if (env && !_environment) {
    validate = false;
  }

  if (env && _environment) {
    if (typeof env === 'string') {
      validate = env === _environment;
    } else if (Array.isArray(env)) {
      validate = env.includes(_environment);
    } else {
      validate = false;
    }
  }

  return validate;
};

const customConfigBasedConsole = (config = {}) => {
  if (customConfigValidate(config)) {
    return bindedConsoleMethods;
  }
  return emptyConsoleMethods;
};

const eventBasedConsole = (eventId: string) =>
  customConfigBasedConsole({ eventId });

type TEnvironmentValueFields = {
  [key in EENVIRONMENTS]: ICustomLogger;
};

interface IConditionalConsole extends TEnvironmentValueFields {
  event: (eventId: string) => ICustomLogger;
  configure: (config: ICustomCondition) => ICustomLogger;
}

const initialConditionalConsole = {
  event: eventBasedConsole,
  configure: customConfigBasedConsole,
};

const conditionalConsole = Object.values(EENVIRONMENTS).reduce((prev, curr) => {
  if (!_environment || _environment.toLowerCase() !== curr.toLowerCase()) {
    return {
      ...prev,
      [curr]: emptyConsoleMethods,
    };
  }

  return {
    ...prev,
    [curr]: bindedConsoleMethods,
  };
}, initialConditionalConsole) as IConditionalConsole;

export { EENVIRONMENTS as ELoggerEnvironments };

export default conditionalConsole;

// Any queries, please feel free to connect with Yugantar
