export const _isEmpty = array => array && array.length === 0;

export const _isArray = arr => arr && Array.isArray(arr);

export const _getEnumKeys = enumObj =>
  Object.keys(enumObj).filter(key => !isNaN(Number(enumObj[key])));

/**
 * Insert item(s) into an array at a specific index.
 *  e.g. const items = ['a', 'b', 'd', 'e'];
 *       _insertIntoArray(items, 2, 'c');
 *       // ['a', 'b', 'c', 'd', 'e'];
 * @param arr - The existing array which we will insert into.
 * @param index - The index which we would like to insert at.
 * @param newItems - The new item(s) which we want to insert.
 */
export const _insertIntoArray = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];
