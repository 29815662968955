import { IProfileDetails } from './DetailsView/types';

export enum EModalViews {
  SEARCH_PEOPLE = 'SEARCH_PEOPLE',
  DETAILS = 'DETAILS',
  BOOK_MEETING_ROOM = 'BOOK_MEETING_ROOM',
  CONFIRM_MEETING_ROOM = 'CONFIRM_MEETING_ROOM',
  MEETING_TYPE = 'MEETING_TYPE',
}

export type TDefaultModalView =
  | EModalViews.SEARCH_PEOPLE
  | EModalViews.DETAILS
  | EModalViews.BOOK_MEETING_ROOM;

export interface IProfileModalProps {
  defaultView: TDefaultModalView;
  details: IProfileDetails;
  onCloseModal: () => void;
  showTicketTagType: boolean;
  hideScheduleAndChat?: boolean;
  meetingIntervalTimeObj: any;
  isInvokedByProfilePreview?: boolean;
  isInterestsEnabledByOrganizer?: boolean;
  showMeetingButton?: boolean;
  showChatButton?: boolean;
}

export enum IMeetingTypes {
  ONLINE = 'ONLINE',
  IN_PERSON = 'IN_PERSON',
}

export enum IMeetingRoomRequestStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  REQUESTED_TIMEOUT = 'REQUESTED_TIMEOUT',
  ACCEPTED_TIMEOUT = 'ACCEPTED_TIMEOUT',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}
