import LogRocket from 'logrocket';

import { SERVICE_URL, LOGROCKET_APP_ID, SETUP_BASE_URL, STUDIO_URL } from '@/config';
import LogrocketFuzzySanitizer from './logrocketSanitizer';
import { gitReleaseHash } from './helpers';
import { WHITEHAT_IDS } from '@/config/whitehatAccounts';

const privateFieldNames = [
    'password',
    'email',
    'phone',
    'phoneNumber',
    'lastName',
    'token',
    'content', //For chat field
    'secret',
];

let isLogrocketInitialized = false;

export function shouldTrackUserInLogrocket(accountId) {
  if (!accountId) {
    return false;
  }

  if (window.location.hostname === 'app.staging.zuddl.io') {
    //Track all staging sessions
    return true;
  }

  if (process.env.NODE_ENV !== 'production') {
    return false;
  }

  return (
    WHITEHAT_IDS.includes(accountId) ||
    (accountId.length > 0 && ['1'].includes(accountId[accountId.length - 1]))
  );
}

export function shouldTrackEventRoleInLogrocket(eventRole){
    if (!eventRole || !eventRole.role) {
        return false;
    }
    if (process.env.NODE_ENV !== 'production') {
        return false;
    }

    return eventRole.role === 'ORGANIZER'
        || eventRole.role === 'SPEAKER'
        || eventRole.role === 'MODERATOR';
}

export function shouldTrackPathInLogrocket() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/sso')) {
        return true;
    }
    if (path.includes('8c3ff25e-2818-4982-bd66-b2cf2095093e')) {
        // This is the event id of Zuddl VO. Enabling recordings for all sessions here.
        return true;
    }
    if (path.includes('3e0cc25c-6bef-4fb4-a6b3-53dc9f13cea0')) {
        // Recording sessions for everyone in the microsoft event to track networking bug.
        // Remove this later. Added on 22 Jul 2021.
        return true;
    }
    if (path.includes('e0dabfe0-80f0-4eda-b49c-fdcfa057cc70')) {
        // Recording sessions for everyone in the global fintech event to track registration bug.
        // Remove this later. Added on 22 Jul 2021.
        return true;
    }
    if (path.includes('13e86698-8caf-428e-b8e8-ec2ff7b297eb')) {
        // This is the event id of Bayer event. Enabling recordings for all sessions here.
        return true;
    }
    if (path.includes('9291385d-b1ed-4841-8c5e-f317cdbe834d')) {
        // This is the event id for internal Bayer testing
        return true;
    }
    return false;
}

export function shouldTrackAllUsersInLogrocket(organizationId: string) {
  const path = window.location.pathname;
  const isRecordingView =
    path.includes('/l/a/r/event') || path.includes('/l/r/event');
  if (isRecordingView) {
    return false;
  }
  return [
    'f03d8241-7fe5-4eda-9ed3-091f28b4454d', // [PROD] Bayer
    'bbf4f258-b741-407b-aac5-94f689fdd69c', // Zuddl-PreProd
  ].includes(organizationId);
}

// See Privacy docs https://docs.logrocket.com/docs/privacy
//                  https://docs.logrocket.com/reference#network

export function initLogRocket() {
    if (isLogrocketInitialized) {
        return;
    }

    const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);
    console.log('Release hash', gitReleaseHash());
    LogRocket.init(LOGROCKET_APP_ID, {
        release: gitReleaseHash(),
        console: {
            isEnabled: {
                log: false,
                debug: false,
            },
        },
        dom: {
            // textSanitizer: true,
            inputSanitizer: true, //Don't record input fields
        },
        network: {
            isEnabled: true,
            requestSanitizer: request => {
                const url = request.url.toLowerCase();
                //Only log our API calls (Do not log 3rd party APIs)
                if (!url.includes(SERVICE_URL) && !url.includes('.zuddl.') && !url.includes('vivenu.com')) {
                    return null;
                }
                if (url.endsWith('.js') || url.endsWith('.css')) {
                    return null; // Don't track static assets
                }
                // Don't log any chat APIs
                // if (request.url.includes('message')) {
                //     return null;
                // }

                return requestSanitizer(request);
            },
            responseSanitizer: response => {
                return responseSanitizer(response);
            },
        },
        mergeIframes: true,
    });
    isLogrocketInitialized = true;
}

// https://docs.logrocket.com/reference#redux-logging
export function logRocketReduxMiddleware() {
    return LogRocket.reduxMiddleware({
        stateSanitizer: function (state) {
            console.log(state);
            return {
                ...state,
                removeThisKey: undefined,
            };
        },
    });
}
