import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { reducer, defaultState } from './pusher-state';
import Pusher from 'pusher-js';
import { SERVICE_URL, PUSHER_KEY, PUSHER_CLUSTER } from '@/config';

const PusherStateContext = createContext({});

export const PusherContainerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    // Pusher.logToConsole = true;
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      authEndpoint: `${SERVICE_URL}/message/auth`,
      auth: {
        headers: {
          'csrf-header': 'true',
        }
      }
    });
    pusher.connection.bind('state_change', function (states) {
      // states = {previous: 'oldState', current: 'newState'}
      console.warn('Pusher state: ' + (states && states.current));
    });
    pusher.connection.bind('error', function (error) {
      console.error('Pusher error: ', error);
    });

    dispatch({ type: 'initPusher', payload: { pusher } });
    return () => {
      try {
        pusher.connection.unbind_all();
        pusher.disconnect();
        dispatch({ type: 'destroyPusher' });
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  return (
    <PusherStateContext.Provider value={state} >
      {children}
    </PusherStateContext.Provider>
  );
};

export function usePusherState() {
  return useContext(PusherStateContext);
}
