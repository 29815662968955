import { IUserPrivacyTypes } from "./userPrivacySetting";

export enum IEventVisibilityOptions {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  UNLISTED = 'UNLISTED',
}

export enum IEventStatusOptions {
  DRAFT = 'DRAFT',
}

export enum EEventType {
  STANDARD = 'STANDARD',
  STUDIO = 'STUDIO',
  WEBINAR = 'WEBINAR',
  SERIES = 'SERIES',
}

export interface IEventObject {
  coverImagePath: string;
  createdAt: string;
  description: string;
  endDateTime: string;
  eventId: string;
  startDateTime: string;
  status: IEventStatusOptions;
  title: string;
  tz: string;
  updatedAt: string;
  visibility: IEventVisibilityOptions;
}

export interface IEventListFormattedEventObject {
  coverImagePath: string;
  createdAt: string;
  description: string;
  endDateTime: string[];
  eventId: string;
  startDateTime: string[];
  status: IEventStatusOptions;
  title: string[];
  tz: string;
  updatedAt: string;
  role: string;
  visibility: IEventVisibilityOptions;
}

export interface EventMemberObject{
  refId?: string;
  presenceRefId?: string;
  limit: number;
  offset: number,
  q: string,
  isSortedByLiveAttendees?: boolean,
  userPrivacyType?: IUserPrivacyTypes,
}

export type IEventObjectList = IEventObject[];
