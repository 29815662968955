import React, { useState } from 'react';
import styles from './styles.module.scss';
import TextWidget from '@/components/custom/widgets/TextWidget';
import produce from 'immer';
import RichTextWidget from '@/components/custom/widgets/FormattedTextWidget';
import MediaWidget from '@/components/custom/widgets/MediaWidget';
//utils
import { addUids } from '@/utils/uid';

const WIDGET_CONFIG = [{
  name: 'Text',
  render: (props) => <TextWidget {...props} />,
  renderSettings: (props) => <TextWidget.Settings {...props} />,
}, {
  name: 'Rich Text',
  render: (props) => <RichTextWidget {...props} />,
  renderSettings: (props) => <RichTextWidget.Settings {...props} />,
}, {
  name: 'Media',
  render: (props) => <MediaWidget {...props} />,
  renderSettings: (props) => <MediaWidget.Settings {...props} />,
}];

const WidgetPage = () => {
  const [widgetValues, setWidgetValues] = useState({});

  const handleWidgetValuesUpdate = (name, values) => {
    setWidgetValues(produce(widgetValues, draft => {
      draft[name] = values;
    }));
  };

  return (
    <>
      <div>Widgets</div>
      {addUids(WIDGET_CONFIG).map(({ name, render, renderSettings, uid }) => (
        <div className={styles.container} key={uid}>
          <div className={styles.name}>{name}</div>
          <div className={styles.row}>
            <div className={styles.settings}>
              {renderSettings({
                onSave: (values) => handleWidgetValuesUpdate(name, values),
                initialValues: widgetValues[name]
              })}
            </div>
            <div className={styles.widget}>
              {render(widgetValues[name])}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default WidgetPage;
