export const isRockCentralDomain = (email) => {
  if (!email) {
    return false;
  }

  return email.toLowerCase().trim().includes("amrock.com") ||
    email.toLowerCase().trim().includes("bedrockdetroit.com") ||
    email.toLowerCase().trim().includes("coredigital.com") ||
    email.toLowerCase().trim().includes("forsalebyowner.com") ||
    email.toLowerCase().trim().includes("nexsystech.com") ||
    email.toLowerCase().trim().includes("onereverse.com") ||
    email.toLowerCase().trim().includes("quickenloans.com") ||
    email.toLowerCase().trim().includes("rocketcommunityfund.org") ||
    email.toLowerCase().trim().includes("rockcentraldetroit.com") ||
    email.toLowerCase().trim().includes("rockconnections.com") ||
    email.toLowerCase().trim().includes("rocksecurity.com") ||
    email.toLowerCase().trim().includes("rockventures.com") ||
    email.toLowerCase().trim().includes("rocketcompanies.com") ||
    email.toLowerCase().trim().includes("rockethomes.com") ||
    email.toLowerCase().trim().includes("rocketloans.com");
};
