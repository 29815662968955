import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router";
import api from '@/api';

const SlugLandingPage = () => {
  const { eventSlug } = useParams();
  let location = useLocation();
  const history = useHistory();

  const [eventId, setEventId] = useState();

  useEffect(() => {
    if (!eventId) {
      return;
    }

    if (location.pathname.includes('/s/a/')) {
      history.push({
        pathname: `/p/a/event/${eventId}`,
        search: location.search
      });
    } else if (location.pathname.includes('/s/')) {
      history.push({
        pathname: `/p/event/${eventId}`,
        search: location.search
      });
    } else {
      history.push({
        pathname: `/p/a/event/${eventId}`,
        search: location.search
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (!eventSlug) {
      return;
    }

    api.event.getIdBySlug(eventSlug)
      .then(({ data: eventId }) => {
        setEventId(eventId);
      });
  }, [eventSlug]);

  return null;
}

export default SlugLandingPage;
