import { useState, useEffect } from 'react';
import { MediaPermissionTypes } from '@/components/ui/modals/StreamPreviewModal/types';
import { getMediaDeviceBrowserData } from '@/components/ui/modals/StreamPreviewModal/utils';

const useLocalMediaPermissions = () => {
  const [localMediaPermissions, setLocalMediaPermissions] = useState({
    audio: MediaPermissionTypes.PROMPT,
    video: MediaPermissionTypes.PROMPT,
  });

  const refetchPermissions = async () => {
    const obj = {
      audio: MediaPermissionTypes.PROMPT,
      video: MediaPermissionTypes.PROMPT,
    };
    try {
      const { navigator } = window;
      const cameraPermission = await navigator.permissions.query({
        name: 'camera',
      });
      const microphonePermission = await navigator.permissions.query({
        name: 'microphone',
      });
      obj.video = cameraPermission.state;
      obj.audio = microphonePermission.state;
    } catch (_err) {
      try {
        await getMediaDeviceBrowserData();
        obj.video = MediaPermissionTypes.GRANTED;
        obj.audio = MediaPermissionTypes.GRANTED;
      } catch (err) {
        obj.video = MediaPermissionTypes.DENIED;
        obj.audio = MediaPermissionTypes.DENIED;
      }
    }
    setLocalMediaPermissions(obj);
    return obj;
  };

  useEffect(() => {
    refetchPermissions();
  }, []);

  return {
    localMediaPermissions,
    refetchPermissions,
  };
};

export default useLocalMediaPermissions;
