import { Channel } from 'pusher-js/types';

export enum IPusherEvents {
  SET_CHANNEL_PRESENTATION = 'SET_CHANNEL_PRESENTATION',
  UPDATE_CHANNEL_PRESENTATION = 'UPDATE_CHANNEL_PRESENTATION',
}

export interface IUseChannelPresentationProps {
  channelId: number;
  attendeeChannel: Channel;
}

interface ICurrentPresentationUI {
  presentationId: string;
  presentationName: string;
  presentationUrl?: string; // URL of the currently presenting file
  currentPresentationPage: number;
  presentationTotalPages: number;
  hasPresentationControl: boolean;
  presentedBy: string;
  isLoading?: boolean;
}

enum IPresentationStatus {
  QUEUED = 'QUEUED',
  PRESENTING = 'PRESENTING',
  PRESENTED = 'PRESENTED',
}

interface ISetCurrentPresentationBackend {
  channelPresentationId: string;
  channelId: number;
  eventId: string;
  presentationUrl: string;
  presentationName: string;
  currentPresentationPage: number;
  presentationStatus: IPresentationStatus;
  presentationOrder: number;
  presentedBy: string;
  totalPages: number;
}
interface IUpdateCurrentPresentationBacked {
  channelPresentationId: string;
  channelId: number;
  currentPresentationPage: number;
}

export type TCurrentPresentationUI = ICurrentPresentationUI | null;
export type TSetCurrentPresentationBackend = ISetCurrentPresentationBackend | null;
export type TUpdateCurrentPresentationBackend = IUpdateCurrentPresentationBacked | null;
export interface IPresentationListObject {
  channelPresentationId: string;
  channelId: number;
  eventId: string;
  presentationUrl: string;
  presentationName: string;
  presentationStatus: IPresentationStatus;
  presentationOrder: number;
}

export interface IUseChannelPresentationResponse {
  presentationList: IPresentationListObject[];
  currentPresentation: TCurrentPresentationUI;
  addPresentation: (
    name: string,
    url: string,
    totalPages: number,
    isTemp?: boolean,
    presentImmediately?: boolean,
  ) => Promise<void>;
  refreshPresentationList: () => void;
  startPresentation: (presentationId: string) => void;
  goToPresentationPage: (page: number) => void;
  stopPresentation: () => void;
  setLoadingPresentation: () => void;
  refreshCurrentPresentationState: () => void;
}
