import React, { useState } from 'react';

import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import IconButton, {
  IUIIcons,
  IIconButtonTypes,
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import { IMeetingTypes } from '../types';
import InputField from '@/components/ui/NewFormComponents/InputField';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

import styles from './styles.module.scss';

const MeetingTypeRoomView = (props: IBookMeetingRoomProps) => {
  const {
    onClickBack,
    onSelect,
    onCloseModal,
    selectedDateTime: { date: selectedDate, time: selectedTime },
  } = props;
  const [meetingTypeObject, setMeetingTypeObject] = useState({
    type: undefined,
    location: undefined,
  });
  const [locationFieldError, setLocationFieldError] = useState();
  const validateForm = () => {
    if (
      meetingTypeObject &&
      meetingTypeObject.location !== undefined &&
      meetingTypeObject.location.length < 5
    ) {
      setLocationFieldError('Location must be greater than 5 characters');
      return false;
    }
    setLocationFieldError();
    return true;
  };
  const handleOnSelectNext = () => {
    if (!validateForm()) {
      return;
    }
    onSelect(selectedDate, selectedTime, meetingTypeObject);
  };
  const inputFieldStyle = {
    background: getCssVar(IContentColors.NEUTRAL_BASE_2),
    color: getCssVar(IContentColors.WHITE),
    border: `2px solid ${getCssVar(IContentColors.NEUTRAL_BASE_3)}`,
    height: '52px',
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IconButton
          defaultIcon={IUIIcons.FEATHER_ARROW_LEFT}
          activeIcon={IUIIcons.FEATHER_ARROW_LEFT}
          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
          tooltip={TooltipTypes.none}
          type={IIconButtonTypes.ROUND_NEUTRAL_BASE_3}
          onClick={onClickBack}
          showDefault
        />
        <Text
          text="Choose a Location"
          textColor={ITextColors.MONOCHROME_CONTRAST}
          textSize={ITextSizes.XLARGE}
          textStyle={ITextStyles.BOLDED}
          textAlign="center"
          textLineHeight="29px"
        />
      </div>
      <div className={styles.body}>
        <Text
          text="How would you like to schedule the meeting?"
          textColor={ITextColors.MONOCHROME_CONTRAST}
          textSize={ITextSizes.MEDIUM}
          textStyle={ITextStyles.BOLDED}
          textLineHeight="29px"
        />
        <div
          className={
            meetingTypeObject && meetingTypeObject.type === IMeetingTypes.ONLINE
              ? styles.activeOptionWrapper
              : styles.inactiveOptionWrapper
          }
          onClick={() => {
            setMeetingTypeObject({
              type: IMeetingTypes.ONLINE,
              location: undefined,
            });
          }}
        >
          Online
        </div>
        <div>
          <div
            className={
              meetingTypeObject &&
              meetingTypeObject.type === IMeetingTypes.IN_PERSON
                ? styles.activeOptionWrapper
                : styles.inactiveOptionWrapper
            }
            onClick={() => {
              setMeetingTypeObject({
                type: IMeetingTypes.IN_PERSON,
                location: '',
              });
            }}
          >
            In Person
          </div>
          {meetingTypeObject &&
            meetingTypeObject.type === IMeetingTypes.IN_PERSON && (
            <InputField 
              isLastElement={true}
              showLabelContainer={false}
              onChange={e =>
                setMeetingTypeObject({
                  ...meetingTypeObject,
                  location: e.target.value,
                })
              }
              placeholder='Add a location'
              error={locationFieldError}
              fieldStyles={inputFieldStyle}
            />
          )}
        </div>
        <IconTextButton
          activeIcon={IUIIcons.FORWARD_NAVIGATION}
          defaultIcon={IUIIcons.FORWARD_NAVIGATION}
          defaultLabel="Next"
          activeLabel="Next"
          onClick={handleOnSelectNext}
          showDefault
          buttonType={IIconTextButtonTypes.COLOURS_NEUTRAL_BASE_3}
          iconSize={20}
          iconLast
          disabled={meetingTypeObject.type === undefined}
          style={{ height: '47px' }}
        />
      </div>
    </div>
  );
};

export default MeetingTypeRoomView;
