import { useEffect } from 'react';

const useWindowEvent = (event: string, callback: any) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useResizeWindowEvent = (callback: any) =>
  useWindowEvent('resize', callback);
