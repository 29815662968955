import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const LoginPage = () => {
  // const {
  //   isLoading,
  //   isAuthenticated,
  //   error,
  //   user,
  //   loginWithRedirect,
  //   logout,
  // } = useAuth0();

  // const history = useHistory();

  // useEffect(() => {
  //   if (isLoading) {
  //     return;
  //   }

  //   if (isAuthenticated) {
  //     history.push("/");
  //     return;
  //   }

  //   loginWithRedirect();
  // }, [isLoading, isAuthenticated]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }

  // return <div>Loading...</div>;
};

export default LoginPage;
