import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import Modal from '@/components/ui/modals/Modal';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import {
  makeSelectEventById,
  makeSelectEventRoleForEvent,
} from '@/models/event';

import styles from './styles.module.scss';
import { IProfileModalProps, EModalViews, TDefaultModalView } from './types';
import { IContentColors } from '@/types';
import { ITicketTagTypes } from '@/types/tickets';
import DetailsView from './DetailsView';
import BookMeetingRoomView from './BookMeetingRoomView';
import ConfirmMeetingRoomView from './ConfirmMeetingRoomView';
import MeetingTypeRoomView from './MeetingTypeRoomView';
import { SmartNetworkingMutateContext } from '../context';
import CloseLine from '@/components/ui/new-icons/CloseLine';
import { getDatesAvailability, getPreviewProfileModalView } from './config';
import { useMediaQuery } from 'react-responsive';
import { SheetRef } from 'react-modal-sheet';
import BottomSheet from '@/components/ui/BottomSheet';
import SearchEventMemberForm from '@/components/SearchEventMemberForm';

const ProfileModal = (props: IProfileModalProps) => {
  const dispatch = useDispatch();
  const { eventId = '' } = useParams() as any;
  const { openAttendeeChat } = useContext(SmartNetworkingMutateContext);
  const {
    onCloseModal,
    defaultView,
    details: userInfo,
    showTicketTagType,
    hideScheduleAndChat = false,
    meetingIntervalTimeObj,
    isInvokedByProfilePreview = false,
    isInterestsEnabledByOrganizer = false,
    showMeetingButton,
    showChatButton,
  } = props;
  const [details, setDetails] = useState(userInfo);
  useEffect(() => {
    setDetails(userInfo);
  }, [userInfo]);
  const [currentView, setCurrentView] = useState<EModalViews>(defaultView);
  const [selectedMeetingDateTime, setSelectedMeetingDateTime] = useState({
    date: undefined,
    time: undefined,
  });
  const [selectedMeetingTypeObject, setSelectedMeetingTypeObject] = useState({
    type: undefined,
    location: undefined,
  });
  const [selectedSnapPoint, setSnapPoint] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const ref = React.useRef<SheetRef>();
  const snapTo = (i: number) => {
    setSnapPoint(i);
    ref.current?.snapTo(i);
  };

  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const eventRole = useMemoizedSelector(makeSelectEventRoleForEvent, eventId);

  // added this new code so that we can filter days based on attendee availability
  const [dateOptions, setDateOptions] = useState<any[]>([]);
  useEffect(() => {
    async function getDates() {
      const options = await getDatesAvailability(event, details);
      setDateOptions(options);
    }
    if (details.accountId) {
      getDates();
    }
  }, [event, details]);
  const handleOpenChat = () => {
    openAttendeeChat(details.accountId);
    onCloseModal();
  };

  useEffect(() => {
    if (dateOptions.length > 0) {
      setSelectedMeetingDateTime(prev => ({
        ...prev,
        date: dateOptions[0].value,
      }));
    }
  }, [dateOptions.length]);

  const handleNextButtonClick = selectedAttendee => {
    setDetails({
      ...selectedAttendee,
      showMeetingButton: true,
      showChatButton: false,
    });
    setCurrentView(EModalViews.BOOK_MEETING_ROOM);
  };

  const ViewComponentMap = {
    [EModalViews.SEARCH_PEOPLE]: () => (
      <SearchEventMemberForm handleNextButtonClick={handleNextButtonClick} />
    ),
    [EModalViews.DETAILS]: () => (
      <DetailsView
        {...details}
        onClickSchenduleMeeting={() =>
          setCurrentView(EModalViews.BOOK_MEETING_ROOM)
        }
        showTicketTagType={showTicketTagType}
        onClickInbox={handleOpenChat}
        onCloseModal={onCloseModal}
        hideScheduleAndChat={hideScheduleAndChat}
        meetingIntervalTimeObj={meetingIntervalTimeObj}
        isInvokedByProfilePreview={isInvokedByProfilePreview}
        isInterestsEnabledByOrganizer={isInterestsEnabledByOrganizer}
      />
    ),
    [EModalViews.BOOK_MEETING_ROOM]: () => (
      <BookMeetingRoomView
        onClickBack={() => setCurrentView(EModalViews.DETAILS)}
        onCloseModal={onCloseModal}
        dateOptions={dateOptions}
        onSelect={(date, time) => {
          setSelectedMeetingDateTime({ date, time });
          // deciding the next page based on ticket types of both attendees, falling back to confirm page if something goes wrong
          const nextView =
            details &&
            details.ticketTagType &&
            eventRole &&
            eventRole.ticketType &&
            eventRole.ticketType.ticketTagType === ITicketTagTypes.IN_PERSON &&
            details.ticketTagType === ITicketTagTypes.IN_PERSON
              ? EModalViews.MEETING_TYPE
              : EModalViews.CONFIRM_MEETING_ROOM;
          setCurrentView(nextView);
        }}
        defaultSelected={selectedMeetingDateTime}
        meetingIntervalTimeObj={meetingIntervalTimeObj}
        selectedAcoountId={details.accountId}
      />
    ),
    [EModalViews.MEETING_TYPE]: () => (
      <MeetingTypeRoomView
        onClickBack={() => setCurrentView(EModalViews.BOOK_MEETING_ROOM)}
        onCloseModal={onCloseModal}
        selectedDateTime={selectedMeetingDateTime}
        onSelect={(date, time, meetingTypeObject = undefined) => {
          setSelectedMeetingDateTime({ date, time });
          setSelectedMeetingTypeObject(meetingTypeObject);
          setCurrentView(EModalViews.CONFIRM_MEETING_ROOM);
        }}
      />
    ),
    [EModalViews.CONFIRM_MEETING_ROOM]: () => (
      <ConfirmMeetingRoomView
        {...details}
        selectedDateTime={selectedMeetingDateTime}
        selectedMeetingTypeObject={selectedMeetingTypeObject}
        onCloseModal={onCloseModal}
        onClickBack={() => {
          const prevView =
            details &&
            details.ticketTagType &&
            eventRole &&
            eventRole.ticketType &&
            eventRole.ticketType.ticketTagType === ITicketTagTypes.IN_PERSON &&
            details.ticketTagType === ITicketTagTypes.IN_PERSON
              ? EModalViews.MEETING_TYPE
              : EModalViews.BOOK_MEETING_ROOM;
          setCurrentView(prevView);
        }}
        meetingIntervalTimeObj={meetingIntervalTimeObj}
      />
    ),
  };

  const ViewComp = ViewComponentMap[currentView];

  if (!ViewComp) {
    return null;
  }

  if (isInvokedByProfilePreview) {
    const handleCloseModal = () => {
      dispatch({
        type: 'global/closeViewProfileModal',
      });
      // Open Detailed Profile Modal instantly
      dispatch({
        type: 'global/setEditProfileModal',
      });
    };
    const { headerView, footerView } = getPreviewProfileModalView(
      handleCloseModal,
    );

    return (
      <Modal
        noHeader
        noBodyPadding
        autoTrigger
        overlayClass={styles.profilePreviewModalOverlay}
        styleClass="profilePreviewModal"
        render={() => (
          <div className={styles.container}>
            <ViewComp />
          </div>
        )}
        hasHeader
        headerChildren={headerView}
        hasFooter
        footerChildren={footerView}
        footerClassName={styles.profilePreviewFooter}
      />
    );
  }

  return (
    <div>
      {isMobile && (
        <BottomSheet
          ref={ref}
          isOpen={true}
          onSnap={() => setSnapPoint(0)}
          onClose={onCloseModal}
          initialSnap={0}
          snapPoints={[0.8, 0]}
          onBackdropTouch={() => snapTo(1)}
          backdropStyle={{ left: selectedSnapPoint == 0 ? 0 : '-100vw' }}
          id='profileModalBottomSheet'
        >
          <div className={styles.container}>
            <ViewComp />
          </div>
        </BottomSheet>
      )}
      {!isMobile && (
        <Modal
          noHeader
          autoTrigger
          styleClass="smartNetworkingProfileViewModal"
          overlayClass={styles.modalOverlay}
          noBodyPadding
          render={() => (
            <div className={styles.container}>
              <div className={styles.closeModalWrapper}>
                <div className={styles.close} onClick={onCloseModal}>
                  <CloseLine color={IContentColors.NEUTRAL_CONTRAST_2} />
                </div>
              </div>
              <ViewComp />
            </div>
          )}
        />
      )}
    </div>
  );
};

export { EModalViews, TDefaultModalView };

export default ProfileModal;
