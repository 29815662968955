import React, { createContext, useReducer, useContext } from 'react';
import { reducer, defaultState } from './video-state';
import { IVideoState, TSetPaginationConfigPayload, TStreamId, TVideoMutationContext } from './video-state.types';

const VideoStateContext = createContext<IVideoState>({} as IVideoState);
const VideoMutationContext = createContext<TVideoMutationContext>({} as TVideoMutationContext);

export const VideoContainerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const methods = {
    setDevicesList(devicesList) {
      dispatch({
        type: 'setDevicesList',
        payload: devicesList,
      });
    },
    updateMainConfig(config) {
      dispatch({
        type: 'updateMainConfig',
        payload: config,
      });
    },
    setLocalStream(localStream) {
      dispatch({ type: 'setLocalStream', payload: localStream });
    },
    clearLocalStream() {
      dispatch({ type: 'clearLocalStream' });
    },
    publishLocalStream() {
      dispatch({ type: 'publishLocalStream' });
    },
    switchLocalStreamUid({ uid }) {
      dispatch({ type: 'switchLocalStreamUid', payload: { uid } });
    },
    publishLocalStreamGreenRoom() {
      dispatch({ type: 'publishLocalStreamGreenRoom' });
    },
    unpublishLocalStream() {
      dispatch({ type: 'unpublishLocalStream' });
    },
    clearStreamsForSwitch() {
      dispatch({ type: 'clearStreamsForSwitch' });
    },
    clearGreenRoomStreams() {
      dispatch({ type: 'clearGreenRoomStreams' });
    },
    addRemoteUser(user, mediaType) {
      dispatch({ type: 'addRemoteUser', payload: { user, mediaType } });
    },
    removeRemoteUser(user, mediaType) {
      dispatch({ type: 'removeRemoteUser', payload: { user, mediaType } });
    },
    addLocalScreenShare(screenStream) {
      dispatch({ type: 'addLocalScreenShare', payload: screenStream });
    },
    clearScreenShare() {
      dispatch({ type: 'clearScreenShare' });
    },
    addGreenRoomRemoteUser(user, mediaType) {
      dispatch({
        type: 'addGreenRoomRemoteUser',
        payload: { user, mediaType },
      });
    },
    removeGreenRoomRemoteUser(user, mediaType) {
      dispatch({
        type: 'removeGreenRoomRemoteUser',
        payload: { user, mediaType },
      });
    },
    setPrimaryStream(stream) {
      dispatch({ type: 'setPrimaryStream', payload: stream });
    },
    setLoading(isLoading) {
      dispatch({ type: 'setLoading', payload: isLoading });
    },
    setNetworkQuality(stats) {
      dispatch({ type: 'setNetworkQuality', payload: stats });
    },
    setActiveSpeaker(activeSpeaker) {
      dispatch({ type: 'setActiveSpeaker', payload: activeSpeaker });
    },
    setStreamInfo(streamInfo) {
      dispatch({ type: 'setStreamInfo', payload: streamInfo });
    },
    updateConnectionState(connectionInfo) {
      dispatch({ type: 'updateConnectionState', payload: connectionInfo });
    },
    mainReady() {
      dispatch({ type: 'mainReady' });
    },
    greenRoomReady() {
      dispatch({ type: 'greenRoomReady' });
    },
    switchCamera(cameraId: string) {
      dispatch({
        type: 'switchCamera',
        payload: cameraId,
      });
    },
    switchMicrophone(microphoneId: string) {
      dispatch({
        type: 'switchMicrophone',
        payload: microphoneId,
      });
    },
    switchSpeaker(speakerId: string) {
      dispatch({
        type: 'switchSpeaker',
        payload: speakerId,
      });
    },
    addStreamPresence(uid) {
      dispatch({
        type: 'addStreamPresence',
        payload: uid,
      });
    },
    addDialInStreamPresence(uid) {
      dispatch({
        type: 'addDialInStreamPresence',
        payload: uid,
      });
    },
    removeStreamPresence(uid) {
      dispatch({
        type: 'removeStreamPresence',
        payload: uid,
      });
    },
    addGreenRoomStreamPresence(uid) {
      dispatch({
        type: 'addGreenRoomStreamPresence',
        payload: uid,
      });
    },
    removeGreenRoomStreamPresence(uid) {
      dispatch({
        type: 'removeGreenRoomStreamPresence',
        payload: uid,
      });
    },
    setPaginationConfig(config: TSetPaginationConfigPayload) {
      dispatch({
        type: 'setPaginationConfig',
        payload: config,
      })
    },
    setTalkingUsers(uids: TStreamId[]) {
      dispatch({
        type: 'setTalkingUsers',
        payload: uids
      })
    },
    reset() {
      dispatch({
        type: 'reset',
      });
    },
  };

  return (
    <VideoStateContext.Provider value={state}>
      <VideoMutationContext.Provider value={methods}>
        {children}
      </VideoMutationContext.Provider>
    </VideoStateContext.Provider>
  );
};

export function useVideoState() {
  return useContext(VideoStateContext);
}

export function useVideoMutation() {
  return useContext(VideoMutationContext);
}

export function useVideoContext(): [IVideoState, TVideoMutationContext] {
  return [useVideoState(), useVideoMutation()];
}
