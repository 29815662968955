import React, { createContext, useReducer, useContext } from 'react';
import { reducer, defaultState } from './pusher-channel-state';

const PusherChannelStateContext = createContext({});
const PusherChannelMutationContext = createContext({});

export const PusherChannelContainerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const methods = {
    setEventAttendeeChannel(eventAttendeeChannel) {
      dispatch({
        type: 'setEventAttendeeChannel',
        payload: eventAttendeeChannel,
      });
    },
    setEventIndividualUserChannel(eventIndividualUserChannel) {
      dispatch({
        type: 'setEventIndividualUserChannel',
        payload: eventIndividualUserChannel,
      });
    },
    setEventAttendeeTicketTypeChannel(eventAttendeeTicketTypeChannel) {
      dispatch({
        type: 'setEventAttendeeTicketTypeChannel',
        payload: eventAttendeeTicketTypeChannel,
      });
    },
  };

  return (
    <PusherChannelStateContext.Provider value={state}>
      <PusherChannelMutationContext.Provider value={methods}>
        {children}
      </PusherChannelMutationContext.Provider>
    </PusherChannelStateContext.Provider>
  );
};

export function usePusherChannelState() {
  return useContext(PusherChannelStateContext);
}

export function usePusherChannelMutation() {
  return useContext(PusherChannelMutationContext);
}

export function usePusherChannelContext() {
  return [usePusherChannelState(), usePusherChannelMutation()];
}
