import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import api from "@/api";
import { AttendeeChannelEvent } from "@/types/messaging";
import { useDispatch } from 'react-redux';
import { makeSelectCurrentlyPlayingVideoForChannel } from "@/models/event";
import { ChannelVideoStatus } from "@/types/channel";
import { useMemoizedSelector } from "@/hooks/use-memoized-selector";

const useVideoQueue = ({ stageConfig = {}, attendeeChannel }) => {
  const { channelId } = stageConfig;
  const dispatch = useDispatch();
  const currentlyPlayingVideo = useMemoizedSelector(makeSelectCurrentlyPlayingVideoForChannel, channelId);
  const [videoList, setVideoList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if(!channelId) return;

    fetchVideoList();
  }, [channelId]);

  const fetchVideoList = () => {
    if (channelId) {
      api.channel.getAllChannelVideos(channelId).then(({ data }) => {
        setVideoList(data);
      });
    }
  }

  useEffect(() => {
    if (channelId) {

    api.channel.getCurrentlyPlayingVideo(channelId).then(({ data }) => {
      dispatch({
        type: 'event/setCurrentlyPlayingVideo',
        payload: {
          channelId,
          videoInfo: data
        }
      });
    });
  }

    const videoInterval = setInterval(() => {
      if (currentlyPlayingVideo || !location.pathname.includes('stage')) {
        return;
      }

      if (channelId) {
        api.channel.getCurrentlyPlayingVideo(channelId).then(({ data }) => {
          dispatch({
            type: 'event/setCurrentlyPlayingVideoIfNotPlaying',
            payload: {
              channelId,
              videoInfo: data
            }
          });
        });
      }
    }, 60000);

    return (() => {
      dispatch({
        type: 'event/setCurrentlyPlayingVideo',
        payload: {
          channelId,
          videoInfo: null
        }
      });

      clearInterval(videoInterval);
    });
  }, [channelId, location.pathname]);


  const clearCurrentlyPlayingVideo = (currentlyPlayingVideoId) => {
    if (currentlyPlayingVideo.channelVideoId === currentlyPlayingVideoId) {
      dispatch({
        type: 'event/setCurrentlyPlayingVideo',
        payload: {
          channelId,
          videoInfo: null
        }
      });
    }
  };

  const currentlyPlayingVideoRef = useRef(currentlyPlayingVideo);
  useEffect(() => {
    currentlyPlayingVideoRef.current = currentlyPlayingVideo;
  }, [currentlyPlayingVideo]);

  useEffect(() => {
    if (!attendeeChannel) {
      return;
    }

    const updateVideoStatusListener = (data) => {
      const currentlyPlayingVideo = JSON.parse(data);


      // FOR NOW WE WILL ONLY TRIGGER THE PLAY NOT THE STOP
      if (currentlyPlayingVideoRef && currentlyPlayingVideoRef.current &&
        currentlyPlayingVideoRef.current.channelVideoId === currentlyPlayingVideo.channelVideoId &&
        currentlyPlayingVideoRef.current.channelVideoStatus === currentlyPlayingVideo.channelVideoStatus &&
        currentlyPlayingVideoRef.current.playingTime === currentlyPlayingVideo.playingTime) {
        return;
      }

      if ([ChannelVideoStatus.PLAYING].includes(currentlyPlayingVideo.channelVideoStatus)) {
        dispatch({
          type: 'event/setCurrentlyPlayingVideo',
          payload: {
            channelId,
            videoInfo: currentlyPlayingVideo
          }
        });
      } else {
        dispatch({
          type: 'event/setCurrentlyPlayingVideo',
          payload: {
            channelId,
            videoInfo: undefined
          }
        });
      }
    };
    attendeeChannel.bind(AttendeeChannelEvent.UPDATE_VIDEO_STATUS, updateVideoStatusListener);

    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.UPDATE_VIDEO_STATUS, updateVideoStatusListener);
    };
  }, [attendeeChannel]);

  const playVideo = async (video) => {
    await api.channel.updateChannelVideoStatus(channelId, {
      channelVideoId: video.channelVideoId,
      channelVideoStatus: ChannelVideoStatus.PLAYING,
      playingTime: 0,
      isFillerVideo: video.isFillerVideo,
    }).then(({ data }) => {
      dispatch({
        type: 'event/setCurrentlyPlayingVideo',
        payload: {
          channelId,
          videoInfo: data
        }
      });
    });
  };

  const markVideoDone = async (video) => {
    await api.channel.updateChannelVideoStatus(channelId, {
      channelVideoId: video.channelVideoId,
      channelVideoStatus: ChannelVideoStatus.PLAYED,
      playingTime: 0
    }).then(() => {
      dispatch({
        type: 'event/setCurrentlyPlayingVideo',
        payload: {
          channelId,
          videoInfo: undefined
        }
      });
    });
  }

  return {
    videoList,
    clearCurrentlyPlayingVideo,
    currentlyPlayingVideo,
    playVideo,
    markVideoDone,
    refreshVideoList: fetchVideoList,
  };
};

export default useVideoQueue;
