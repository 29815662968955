import api from '@/api';
import { useEffect, useState } from 'react';
import { HostChannelEvent, AttendeeChannelEvent } from '@/types/messaging';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '@/models/account';
import { useParams } from 'react-router';

const useAnimationEffects = ({ attendeeChannel, channelConfig }) => {
  const { eventId = '' } = useParams();
  const [showConfetti, setShowConfetti] = useState();
  const dispatch = useDispatch();

  const startConfetti = () => {
    api.channel.startConfetti(channelConfig.channelId);
  };

  const stopConfetti = () => {
    api.channel.stopConfetti(channelConfig.channelId);
  };

  useEffect(() => {
    if (!attendeeChannel) {
      return;
    }

    const startConfettiListener = (data) => {
      setShowConfetti(true);
    };
    attendeeChannel.bind(AttendeeChannelEvent.CONFETTI_START, startConfettiListener);

    const stopConfettiListener = (data) => {
      setShowConfetti(false);
    };
    attendeeChannel.bind(AttendeeChannelEvent.CONFETTI_STOP, stopConfettiListener);

    return () => {
      attendeeChannel.unbind(AttendeeChannelEvent.CONFETTI_START, startConfettiListener);
      attendeeChannel.unbind(AttendeeChannelEvent.CONFETTI_STOP, stopConfettiListener);
    };
  }, [attendeeChannel]);

  return {
    startConfetti,
    stopConfetti,
    showConfetti
  };
};

export default useAnimationEffects;
