import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import api from '@/api';
import { useParams } from "react-router";
import { IUserPrivacyTypes } from "@/types/userPrivacySetting";

const useAttendeeList = ({
  eventId,
  limit,
  offset,
  q,
  forceReload = false,
  disabled,
  userPrivacyType = IUserPrivacyTypes.INVALID
}) => {

  const { boothId } = useParams();
  const [attendeeListDetails, setAttendeeListDetails] = useState();
  const [attendeeListCount, setAttendeeListCount] = useState();

  const fetchAttendeeInfo = () => {
    if (disabled) {
      return;
    }

    api.event.searchAllEventMembersCount(eventId, q, boothId, userPrivacyType).then(({ data }) => {
      setAttendeeListCount(data);
    });

    if (q === 'undefined' || q === '') {
      api.event.searchAllEventMembers(eventId, { limit, offset, q, refId: boothId }).then(({ data }) => {
        let attendeeList = [];
        //This is for Sorting attendees according to chat / no chat
        data.map(a => {
          if (a.chatId != null) {
            attendeeList.push(a);
          }
        });
        data.map(a => {
          if (a.chatId == null) {
            attendeeList.push(a);
          }
        });
        setAttendeeListDetails(data);
      });
    }
    else {
      api.event.searchAllEventMembers(eventId, { limit, offset, q, refId: boothId, userPrivacyType }).then(({ data }) => {
        setAttendeeListDetails(data);
      });
    }
  }

  useEffect(() => {
    fetchAttendeeInfo();
  }, [eventId, offset, q])

  useEffect(() => {
    if (forceReload) {
      fetchAttendeeInfo();
    }
  }, [forceReload])

  return {
    attendeeListDetails, attendeeListCount
  };

}

export default useAttendeeList;
