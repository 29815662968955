// Email Validator
export const isValidEmail = email => {
  const emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/g;
  return emailRegex.test(email);
};

export const isValidUrl = value => {
  const url = value?.trim().toLowerCase();
  if (!url) {
    return false;
  }
  return url.startsWith('http://') || url.startsWith('https://');
};
