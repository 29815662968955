import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsStageVolumeOn } from '@/models/global';

const useStageVolumeToggle = ({ showVolumeToggle, parent }) => {
  const dispatch = useDispatch();
  const [volumeOn, setVolumeOn] = useState(true);

  const toggleVolume = (e) => {
    if (e) {
      e.stopPropagation();
    }
    dispatch({
      type: 'global/toggleStageVolume',
    });
  };

  const globalStageVolumeOn = useSelector(selectIsStageVolumeOn);

  useEffect(() => {
    if (showVolumeToggle) {
      setVolumeOn(globalStageVolumeOn);
    } else {
      setVolumeOn(true);
    }
  }, [globalStageVolumeOn, showVolumeToggle]);

  return {
    volumeOn,
    toggleVolume,
    showVolumeToggle,
  };
};

export default useStageVolumeToggle;
