import React from 'react';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

export const LeftIcon = ({ color }) => {
  const stroke = color || "#17345F";
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.25 12.9167L2 7.66667L7.25 2.41667" stroke={stroke} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export const EnterIcon = ({ color }) => {
  const stroke = color || "#17345F";
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2.375L10 22.625M17.875 10.25L10 2.375L17.875 10.25ZM10 2.375L2.125 10.25L10 2.375Z" stroke={stroke} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export const RightIcon = ({ color }) => {
  const stroke = color || "#17345F";
  return (
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.75 12.9167L7 7.66669L1.75 2.41669" stroke={stroke} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export const OpenIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 3H3C1.89543 3 1 3.89543 1 5V15C1 16.1046 1.89543 17 3 17H13C14.1046 17 15 16.1046 15 15V11M11 1H17M17 1V7M17 1L7 11" stroke="#CDCDCD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const CrossIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#4A5568"/>
  </svg>
)

export const HandIcon = () => {
  const fillColor = getCssVar(IContentColors.SECONDARY);
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3V8V8.5C11 8.77614 11.2239 9 11.5 9C11.7761 9 12 8.77614 12 8.5V8V4C12 3.44772 12.4477 3 13 3C13.5523 3 14 3.44772 14 4V8V8.5C14 8.77614 14.2239 9 14.5 9C14.7761 9 15 8.77614 15 8.5V8V6C15 5.44772 15.4477 5 16 5C16.5523 5 17 5.44772 17 6V11C17 14.866 13.866 18 10 18C6.13401 18 3 14.866 3 11V9C3 8.44772 3.44772 8 4 8C4.55228 8 5 8.44772 5 9V11V11.5C5 11.7761 5.22386 12 5.5 12C5.77614 12 6 11.7761 6 11.5V11V10V8V4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V8V8.5C8 8.77614 8.22386 9 8.5 9C8.77614 9 9 8.77614 9 8.5V8V3Z" fill={fillColor}/>
    </svg>
  );
}

export const TickIcon = () => {
  const fillColor = getCssVar(IContentColors.SECONDARY);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke={fillColor} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check">
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  )
}

export const GiftIcon = ({ color, size }) => {
  const fillColor = color || getCssVar(IContentColors.SECONDARY);
  const iconSize = size || 20;
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H4V20C4 21.104 4.896 22 6 22H11V12H6H5ZM18 12H13V22H18C19.104 22 20 21.104 20 20V12H19H18ZM18.791 7C18.922 6.589 19 6.096 19 5.5C19 3.57 17.43 2 15.5 2C13.878 2 12.795 3.482 12.096 5.085C11.407 3.57 10.269 2 8.5 2C6.57 2 5 3.57 5 5.5C5 6.096 5.079 6.589 5.209 7H2V11H11V9H12H13V9.091V11H22V7H18.791ZM7 5.5C7 4.673 7.673 4 8.5 4C9.388 4 10.214 5.525 10.698 7H8C7.626 7 7 7 7 5.5ZM15.5 4C16.327 4 17 4.673 17 5.5C17 7 16.374 7 16 7H13.523C14.033 5.424 14.774 4 15.5 4Z"
        fill={fillColor}
      />
    </svg>
  );
};