export enum IVideoModeType {
  LIVE = 'live',
  RTC = 'rtc',
}

export enum IVideoStatusType {
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  CANCELLED = 'CANCELLED',
  ERRORED = 'ERRORED'
}