import moment from 'moment';
import { isEmpty } from 'lodash';
import { getSimpleDate } from '@/utils/helpers';
export const SCHEDULE_MEETING_TAB = (upcoming, pending, past) => [
  {
    id: 'table-upcoming',
    content: `Upcoming (${upcoming})`,
  },
  {
    id: 'table-pending',
    content: `Pending (${pending})`,
  },
  {
    id: 'table-past',
    content: `Past (${past})`,
  },
];

const hasDayAccess = (dayAccessList, startDateTime) => {
  if (!dayAccessList || dayAccessList.length === 0) {
    return true;
  }
  const currentDateFormat = getSimpleDate(startDateTime);
  return dayAccessList.some(
    item => item.isActive && getSimpleDate(item.accessDate) === currentDateFormat,
  );
};

export const scheduledDateOptionsList = (event, monthList, dayAccessList) => {
  const dateOptionsList = [] as any;
  let currentDate = new Date(event.startDateTime);
  currentDate.setHours(0, 0, 0, 0);
  let endDate = new Date(event.endDateTime);
  endDate.setHours(0, 0, 0, 0);
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  while (currentDate <= endDate) {
    let startDateTime = new Date(currentDate);

    let date = ``;

    if (today.getTime() === startDateTime.getTime()) {
      date = `${startDateTime.getDate()}, ${monthList[startDateTime.getMonth()]} ${startDateTime.getFullYear()} (Today)`;
      if (hasDayAccess(dayAccessList, startDateTime)) {
        dateOptionsList.push({ label: date, value: `${startDateTime}` });
      }
    } else if (tomorrow.getTime() === startDateTime.getTime()) {
      date = `${startDateTime.getDate()}, ${monthList[startDateTime.getMonth()]} ${startDateTime.getFullYear()} (Tomorrow)`;
    } else {
      date = `${startDateTime.getDate()}, ${monthList[startDateTime.getMonth()]} ${startDateTime.getFullYear()}`;
    }
    if (
      currentDate.getTime() > today.getTime() &&
      hasDayAccess(dayAccessList, startDateTime)
    ) {
      dateOptionsList.push({ label: date, value: `${startDateTime}` });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateOptionsList;
};

export const scheduledTimeOptionsList = availableSlots => {
  const timeOptionsList = [] as any;
  if (isEmpty(availableSlots)) {
    return timeOptionsList;
  }

  availableSlots.forEach(timeSlot => {
    timeOptionsList.push({
      label: moment(new Date(timeSlot.slot)).format('h:mm A'),
      value: {
        hours: new Date(timeSlot.slot).getHours(),
        minutes: new Date(timeSlot.slot).getMinutes(),
      },
      status: timeSlot.meetingRoomRequestStatus || '',
    });
  });
  return timeOptionsList;
};

export const getTimeSlots = (
  event,
  selectedMeetingDate,
  meetingsSettingsAndIntervalTime,
) => {
  let tt = 0; // start time
  let ap = ['AM', 'PM']; // AM-PM
  const timeOptionsList = [] as any;
  let today = new Date();

  let selectedDate = new Date(`${selectedMeetingDate}`);

  let eventStartDate = new Date(event.startDateTime);
  let eventEndDate = new Date(event.endDateTime);

  let timeInterval =
    Number(
      meetingsSettingsAndIntervalTime &&
        meetingsSettingsAndIntervalTime.durationMins,
    ) || 15; //minutes interval

  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let mm = tt % 60; // getting minutes of the hour in 0-55 format

    let hour = ('0' + (hh % 12)).slice(-2);
    let minute = ('0' + mm).slice(-2);
    let ampm = ap[Math.floor(hh / 12)];

    if (ampm == 'PM' && hour == '00') {
      hour = '12';
    }
    let generatedTime = `${hour}:${minute} ${ampm}`;
    let timeObject = { hours: hh, minutes: mm };

    if (eventStartDate.getDate() == selectedDate.getDate()) {
      if (eventStartDate.getHours() <= hh) {
        if (today.getDate() == selectedDate.getDate()) {
          if (today.getHours() < hh) {
            timeOptionsList.push({
              label: `${generatedTime}`,
              value: timeObject,
            });
          } else if (today.getHours() == hh) {
            if (today.getMinutes() <= mm) {
              timeOptionsList.push({
                label: `${generatedTime}`,
                value: timeObject,
              });
            }
          }
        } else {
          timeOptionsList.push({
            label: `${generatedTime}`,
            value: timeObject,
          });
        }
      }
    } else if (eventEndDate.getDate() == selectedDate.getDate()) {
      if (eventEndDate.getHours() >= hh) {
        if (today.getDate() == selectedDate.getDate()) {
          if (today.getHours() < hh) {
            timeOptionsList.push({
              label: `${generatedTime}`,
              value: timeObject,
            });
          } else if (today.getHours() == hh) {
            if (today.getMinutes() <= mm) {
              timeOptionsList.push({
                label: `${generatedTime}`,
                value: timeObject,
              });
            }
          }
        } else {
          timeOptionsList.push({
            label: `${generatedTime}`,
            value: timeObject,
          });
        }
      }
    } else {
      if (today.getDate() == selectedDate.getDate()) {
        if (today.getHours() < hh) {
          timeOptionsList.push({
            label: `${generatedTime}`,
            value: timeObject,
          });
        } else if (today.getHours() == hh) {
          if (today.getMinutes() <= mm) {
            timeOptionsList.push({
              label: `${generatedTime}`,
              value: timeObject,
            });
          }
        }
      } else {
        timeOptionsList.push({
          label: `${generatedTime}`,
          value: timeObject,
        });
      }
    }
    tt = tt + timeInterval;
  }
  return timeOptionsList;
};
